// Action Types
const TOGGLE_HELPS = "TOGGLE_HELPS";
const CHANGE_ACTIVE = "CHANGE_ACTIVE";
const initialState: any = {
  show_helps: false,
  activeHelp: false,
};

// Reducer
export function showHelps(
  state = initialState,
  action: { type: any; payload: any }
) {
  switch (action.type) {
    case TOGGLE_HELPS:
      return {
        ...state,
        show_helps: !state.show_helps,
      };
    case CHANGE_ACTIVE:
      return {
        ...state,
        activeHelp: action.payload.active,
      };
    default:
      return state;
  }
}

// Action Creators
export function toggleShowHelps() {
  return { type: TOGGLE_HELPS };
}
export function chnageActive(active: boolean) {
  return { type: CHANGE_ACTIVE, payload: { active } };
}
