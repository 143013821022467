import React, { useEffect, useState } from "react";
import "./orderBoxStyles.css";
import axios from "axios";

function OrderBox(props: any) {
  const [order /*setOrder*/] = useState(props.order);
  const [orderTime, setOrderTime] = useState<any>();

  function getRoundedDate(x: any, collectionTime: any, deliveryTime?: any) {
    // Convert the input strings to Date objects

    const collectionDate: any = getDateWithoutTimeZone(collectionTime);
    const deliveryDate: any = getDateWithoutTimeZone(deliveryTime);

    // Round up the collection time to the nearest x minutes
    const collectionMinutes: any = collectionDate.getMinutes();
    const collectionRoundedMinutes: any = Math.round(collectionMinutes / x) * x;
    const collectionDiffMinutes = collectionRoundedMinutes - collectionMinutes;
    collectionDate.setMinutes(collectionRoundedMinutes);
    collectionDate.setHours(collectionDate.getHours());
    // Add the difference in collection time to the delivery time
    // const deliveryDiffMinutes =
    //   collectionDiffMinutes + ((x - (deliveryDate.getMinutes() % x)) % x);

    deliveryDate.setMinutes(deliveryDate.getMinutes() + collectionDiffMinutes);

    deliveryDate.setHours(deliveryDate.getHours());
    // Convert the Date objects back to strings in the format "hh:mm am/pm"
    const collectionTimeString = collectionDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const deliveryTimeString = deliveryDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    setOrderTime({
      collectionTime: convertTime12to24(collectionTimeString),
      deliveryTime: convertTime12to24(deliveryTimeString),
    });
    // Return the rounded times as an object
  }

  const convertTime12to24 = (time12h: {
    split: (arg0: string) => [any, any];
  }) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (modifier === "PM") {
      hours = ((parseInt(hours, 10) % 12) + 12).toString();
    } else if (parseInt(hours, 10) === 12 && modifier === "PM") {
      hours = "00";
    }

    return `${hours}:${minutes}`;
  };
  const getDateWithoutTimeZone = (time: string | Date) => {
    if (time instanceof Date) {
      time = time.toLocaleString().replace(".000Z", "");
    }
    return new Date(new Date(time.replace(".000Z", "")).getTime());
  };

  useEffect(() => {
    getRoundedDate(
      props.roundingTime,
      order.food_orders.real_pickup_time,
      order.food_orders.real_delivery_time
    );
  }, [props.roundingTime]);
  // useEffect(()=>{
  //   const fetchRunnerOrders = async (id: any) => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API}/food/order/user`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${props.data.accessToken}`,
  //           },
  //           params: {
  //             id: Number(id),
  //           },
  //         }
  //       );
  //       console.log(response);
  //       setTotalOrders(response.data.data.length);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  // },[])

  if (order) {
    return (
      <div>
        <div className="container_order">
          <div className="left">
            <span className="order_number"> {`#${order.number}`}</span>
            <div className="Time_container">
              <div className="time">{orderTime?.collectionTime}</div>
              <div className="dotted-line">
                <span className="walking_time">
                  {`${order.food_orders.walking_time}'`}
                  {Number(order.food_orders.walking_time) < 10 && "⚡"}
                </span>
              </div>
              <div className="time">{orderTime?.deliveryTime}</div>
            </div>
            <div className="user_name">
              <span
                style={{
                  color: "  rgb(4, 159, 187)",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >{`${order.users.name},${order.users.lastname}`}</span>
            </div>
          </div>
          <div>
            {/* <span>{JSON.stringify(props?.totalUserOrders)}</span> */}
          </div>
          <div className="right">
            <div>
              {`${order.store.business_name}`}
              <span style={{ color: "rgb(80, 246, 255)" }}> {" > "}</span>
              <span>{` ${order.food_orders.delivery_address}`}</span>
            </div>

            <div
              className="mb-1"
              style={{
                // ...order.status.styles,
                // color: "rgb(80, 246, 255)",
                marginTop: "5px",
              }}
            >
              <span className="p-1 rounded" style={{ ...order.status.styles }}>
                {`${order.status.name}`}
              </span>
            </div>
            <button
              className="see-direction-btn"
              onClick={() => {
                console.log(props.showDirection());

                if (props.showDirection) {
                  props.showDirection();
                }
              }}
            >
              <span className="text-[black]">See Direction</span>
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>No orders</div>;
  }
}

export default OrderBox;
