/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import WorkHourIndicator from "./statusIndicator/statusIndicator";
import OrderBox from "./orderBox/orderbox";
import axios from "axios";
import { DateTime } from "luxon";
import CloseIcon from "@mui/icons-material/Close";
import { setselectedRunners } from "../../store/showRunners";
import { useDispatch } from "react-redux";

import gsap from "gsap";
import { Draggable } from "gsap/Draggable";
import { OrderStatusEnum } from "../constants/orderStatusEnum";

gsap.registerPlugin(Draggable);

function RunnerDetail(props: any) {
  const [runnerOrders, setOrders] = useState<any>([]);
  const [workingHour, setWorkingHour] = useState<any>();
  const [registrationDate, setResgistrationDate] = useState<any>();
  const [totalOrders, setTotalOrders] = useState<any>(0);
  const [remainingSlots, setRemainingSlots] = useState<any>([]);
  const dispatchRedux = useDispatch();
  const [lastUpdateTime, setLastUppdateTime] = useState("");
  const [image_url, setImage_url] = useState<any>();
  const [roundingTime, setRoundingTime] = useState(10);
  useEffect(() => {
    setImage_url(
      `${process.env.REACT_APP_API}/media/upload/runnerProfile/${props.data?.data?.users.id}`
    );
  }, [props]);

  const [imageExists, setIMageExists] = useState(false);

  const checkImageExists = async (url: RequestInfo | URL) => {
    try {
      const response = await fetch(url, { method: "HEAD" });
      return response.ok;
    } catch (error) {
      console.error("Error checking image:", error);
      return false;
    }
  };

  useEffect(() => {
    if (props.data?.data?.users.id) {
      console.log(imageExists);

      checkImageExists(image_url).then((exists) => {
        if (exists) {
          setIMageExists(true);

          console.log(`Image exists:${image_url}`);
        } else {
          setIMageExists(false);
          console.log("Image does not exist.");
        }
      });
    }
  }, [image_url]);

  useEffect(() => {
    if (props.data.runner_order.length > 0) {
      setOrders(
        props.data.runner_order.filter((ord: any) => {
          return ord.runner_orders[0]?.user_id === props.data?.data?.users.id;
        })
      );
    }
  }, [props]);

  const encodedMessage = encodeURIComponent(
    `Hello ${props.data?.data?.users.fullname}`
  );
  const whatsappMessageLink = `https://wa.me/${props.data?.data?.users.phone}?text=${encodedMessage}`;
  const today = new Date();
  const monthLimitDay = DateTime.fromJSDate(today).plus({ day: 1 }).toJSDate();

  useEffect(() => {
    const regDate = props.data.data.users?.createdDate?.split("T")[0];
    const accessToken = props.data.accessToken;
    const targetRunnerId = String(props.data.data.runner_id);

    setResgistrationDate(regDate);

    const fetchRunnerOrders = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/food/runner/orders`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              user_id: Number(targetRunnerId),
            },
          }
        );
        console.log("response.data.data.length", response.data.data);

        setTotalOrders(response.data.data.length);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchData = async () => {
      const targetRunnerId = String(props.data.data.runner_id);
      const id_runner_role = props.data.data.users.id_runner_role;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/food/saturations/get_working_hours`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              id_runner_role: Number(id_runner_role),
              date_from: DateTime.fromJSDate(today).toFormat("yyyy-MM-dd"),
              date_to:
                DateTime.fromJSDate(monthLimitDay).toFormat("yyyy-MM-dd"),
            },
          }
        );

        const hoursWithRunners: any = response.data.data
          .flatMap((item: any) => item.hours)
          .filter(
            (hour: any) =>
              hour.runners &&
              hour.runners.some(
                (runner: any) => runner.id_runner === targetRunnerId
              )
          );

        let tempRemainingSlots = [];
        if (hoursWithRunners.length > 0) {
          let startTime = hoursWithRunners[0].start;
          let endTime = hoursWithRunners[0].end;

          for (let i = 1; i < hoursWithRunners.length; i++) {
            let currentStart = hoursWithRunners[i].start;
            let currentEnd = hoursWithRunners[i].end;

            if (new Date(currentStart) <= new Date(endTime)) {
              // Extend the end time if current start is before or equal to current end
              endTime =
                new Date(currentEnd) > new Date(endTime) ? currentEnd : endTime;
            } else {
              // For a gap, check if current start is closer to now
              let now = new Date();

              if (
                Math.abs(new Date(currentStart).getTime() - now.getTime()) >
                Math.abs(new Date(startTime).getTime() - now.getTime())
              ) {
                startTime = currentStart;
                endTime = currentEnd;
              } else {
                tempRemainingSlots.push({
                  start: `${DateTime.fromJSDate(today).toFormat(
                    "yyyy-MM-dd"
                  )}T${currentStart.split("T")[1]}`,
                  end: `${DateTime.fromJSDate(today).toFormat("yyyy-MM-dd")}T${
                    currentEnd.split("T")[1]
                  }`,
                });
              }
            }
          }

          const sortedSlots = [...tempRemainingSlots].sort(
            (a: any, b: any) =>
              new Date(a.start).getHours() - new Date(b.start).getHours()
          );
          setRemainingSlots(sortedSlots);
          setWorkingHour({
            start: `${DateTime.fromJSDate(today).toFormat("yyyy-MM-dd")}T${
              startTime.split("T")[1]
            }`,
            end: `${DateTime.fromJSDate(today).toFormat("yyyy-MM-dd")}T${
              endTime.split("T")[1]
            }`,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (props.data?.data?.runner_id) {
      fetchData();
      fetchRunnerOrders();
    }
  }, [props]);

  const getDateWithoutTimeZone = (time: string | Date) => {
    if (time instanceof Date) {
      time = time.toLocaleString().replace(".000Z", "");
    }
    return new Date(new Date(time.replace(".000Z", "")).getTime());
  };

  const RunnerType = (role: Number): string => {
    let runnerType = "";

    switch (role) {
      case 1:
        runnerType = "Delivery";
        break;
      case 2:
        runnerType = "Pro";
        break;
      case 3:
        runnerType = "Trial";
        break;
      case 4:
        runnerType = "Junior";
        break;
      case 5:
        runnerType = "Senior";
        break;
      case 6:
        runnerType = "TrasBagagli";
        break;
      case 7:
        runnerType = "Trial";
        break;
      case 8:
        runnerType = "prova nuovo sistema a slot";
        break;
      case 9:
        runnerType = "SMART25";
        break;
      default:
        break;
    }

    return runnerType;
  };
  const cardRef = useRef(null);

  useEffect(() => {
    // Create a single Draggable instance
    const draggableInstance = Draggable.create(cardRef.current, {
      type: "x,y",
      bounds: window,
      inertia: true,
    });

    return () => {
      draggableInstance.forEach((instance) => instance.kill());
    };
  }, []);
  function getLatestTimeAgo(record: any) {
    const createdAtLocalStr = new Date(record.created_at);
    const updatedAtLocalStr = new Date(record.updated_at);

    const createdAt = DateTime.fromISO(createdAtLocalStr.toISOString());
    const updatedAt = DateTime.fromISO(updatedAtLocalStr.toISOString());

    const latest = updatedAt > createdAt ? updatedAt : createdAt;

    const now = DateTime.now();

    const diff = now.diff(latest, ["days", "hours", "minutes", "seconds"]);

    if (diff.as("seconds") < 60) {
      return "just now";
    }

    const days = Math.floor(diff.days);
    const hours = Math.floor(diff.hours);
    const minutes = Math.floor(diff.minutes);

    const parts = [];

    if (days > 0) {
      parts.push(`${days} day${days === 1 ? "" : "s"}`);
    }
    if (hours > 0) {
      parts.push(`${hours} hour${hours === 1 ? "" : "s"}`);
    }
    if (minutes > 0) {
      parts.push(`${minutes} minute${minutes === 1 ? "" : "s"}`);
    }

    if (parts.length === 0) {
      // If it's at least 60 seconds but under 1 minute, still say "just now"
      return "just now";
    }

    return parts.join(" ") + " ago";
  }

  useEffect(() => {
    const updatedTime = getLatestTimeAgo(props.data.data);
    setLastUppdateTime(updatedTime);
  }, [props.data.data.users.id]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/setting`, {
        headers: {
          Authorization: `Bearer ${props.access_token}`,
        },
      })
      .then((response) => {
        setRoundingTime(response.data?.data[0]?.time_round_ritiro ?? 1);
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
      });
  }, [props.access_token]);

  return (
    <div style={{ zIndex: 1 }} ref={cardRef} className="card min-w-[250px]">
      <div className="flex justify-end mb-2 cursor-pointer">
        <span className="text-[white] text-sm">{lastUpdateTime}</span>
        <CloseIcon
          onClick={() => {
            dispatchRedux(setselectedRunners(props.data));
          }}
          style={{ color: "#ff0909" }}
        ></CloseIcon>
      </div>
      <div className="top-section">
        <div className="container">
          <div className="profile-image">
            {!imageExists ? (
              <img src={`${process.env.PUBLIC_URL}/user.png`} alt="Profile" />
            ) : (
              <img
                style={{ borderRadius: "12px" }}
                src={image_url}
                alt="Description of the image"
              />
            )}
          </div>
          <div className="info">
            <div
              className="info-section"
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {props.data?.data?.users.fullname}
            </div>

            <div className="info-section">
              <a
                style={{ color: "black", textDecoration: "none" }}
                href={`tel:${props.data?.data?.users.phone}`}
              >
                {props.data?.data?.users.phone}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        {/* <span className="title"> {props.data?.data?.users.fullname}</span> */}
        <div className="row row1">
          <div className="item -mt-10">
            <span className="big-text">
              <span className="regular-text">Type of runner </span>
              {RunnerType(Number(props.data.data.users.id_runner_role))}
            </span>
          </div>
        </div>
        {workingHour && (
          <div className="statusIndicator">
            <WorkHourIndicator
              startTime={new Date(getDateWithoutTimeZone(workingHour.start))}
              endTime={new Date(getDateWithoutTimeZone(workingHour.end))}
              isCurrent={true}
            />

            {remainingSlots && remainingSlots.length > 0 && (
              <div className="mb-4">
                <h4 className="remainin-title">Also booked</h4>
                {remainingSlots.map((slot: any, index: any) => (
                  <div className="other-slots" key={index}>
                    <WorkHourIndicator
                      startTime={new Date(getDateWithoutTimeZone(slot.start))}
                      endTime={new Date(getDateWithoutTimeZone(slot.end))}
                      isCurrent={false}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* <span className="order_text"> Orders </span> */}
        <div className="orders_list">
          {runnerOrders.length > 0 ? (
            runnerOrders
              .filter((ord: any) => {
                return (
                  ord.status.name !== OrderStatusEnum.CONSEGNATO &&
                  ord.status.name !==
                    OrderStatusEnum.RIFIUTATO_DAL_RISTORANTE &&
                  ord.status.name !== OrderStatusEnum.RIFIUTATO_DA_COCAI
                );
              })
              .sort((a: any, b: any) => {
                const timeA = new Date(
                  a.food_orders.real_pickup_time
                ).getTime();
                const timeB = new Date(
                  b.food_orders.real_pickup_time
                ).getTime();
                return timeA - timeB; // ascending order
              })
              .map((order: any) => {
                return (
                  <OrderBox
                    roundingTime={roundingTime}
                    key={order.id}
                    order={order}
                    showDirection={() => {
                      console.log(props.showDirection());
                      props.showDirection(order);
                    }}
                    access_token={props.data.accessToken}
                  />
                );
              })
          ) : (
            <span className="order_text_no_orders">
              No orders at the moment
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default RunnerDetail;
