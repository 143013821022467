// Action Types
const TOGGLE_RUNNERS = "TOGGLE_RUNNERS";
const SET_AVAILABLE_RUNNERS = "SET_AVAILABLE_RUNNERS";
const SET_SELECTED_RUNNERS = "SET_SELECTED_RUNNERS";
const SET_SELECTED_RUNNERS_VIEW = "SET_SELECTED_RUNNERS_VIEW";

const REMOVE_ALL = "REMOVE_ALL";
// Initial State
const initialState: any = {
  show_runners: false,
  available_runners: [],
  selected_runners: [],
  selected_runners_view: [],
};

// Reducer
export function showRunners(
  state = initialState,
  action: { type: any; payload: any }
) {
  switch (action.type) {
    case TOGGLE_RUNNERS:
      console.log("getting", state.show_runners);
      return {
        ...state,
        show_runners: !state.show_runners,
      };
    case SET_AVAILABLE_RUNNERS:
      return {
        ...state,
        available_runners: action.payload.runners,
      };
    case SET_SELECTED_RUNNERS:
      const incomingRunner = action.payload.runners;
      // e.g., { type: "runner", data: {...}, runner_order: ..., accessToken: ... }
      // Suppose the unique ID is in incomingRunner.data.users.id

      const runnerId = incomingRunner.data.runner_id;

      // Check if the runner already exists in selected_runners
      const runnerExists = state.selected_runners.some(
        (runner: any) => runner.data.runner_id === runnerId
      );

      let updatedRunners;
      if (runnerExists) {
        // Remove
        updatedRunners = state.selected_runners.filter(
          (runner: any) => runner.data.users.id !== runnerId
        );
      } else {
        // Add
        updatedRunners = [...state.selected_runners, incomingRunner];
      }

      return {
        ...state,
        selected_runners: updatedRunners,
      };
    case SET_SELECTED_RUNNERS_VIEW:
      const incomingRunner_View = action.payload.runners;
      // e.g., { type: "runner", data: {...}, runner_order: ..., accessToken: ... }
      // Suppose the unique ID is in incomingRunner.data.users.id

      const runnerId_view = incomingRunner_View.data.runner_id;

      // Check if the runner already exists in selected_runners
      const runnerExists_view = state.selected_runners_view.some(
        (runner: any) => runner.data.runner_id === runnerId_view
      );

      let updatedRunners_view;
      if (runnerExists_view) {
        // Remove
        updatedRunners_view = state.selected_runners_view.filter(
          (runner: any) => runner.data.users.id !== runnerId_view
        );
      } else {
        // Add
        updatedRunners_view = [
          ...state.selected_runners_view,
          incomingRunner_View,
        ];
      }

      return {
        ...state,
        selected_runners_view: updatedRunners_view,
      };

    case REMOVE_ALL:
      return {
        show_Runners: false,
      };
    default:
      return state;
  }
}

// Action Creators
export function changeShowRunners(mode: boolean) {
  return { type: TOGGLE_RUNNERS, payload: { mode } };
}
export function setAvailableRunners(runners: any) {
  return { type: SET_AVAILABLE_RUNNERS, payload: { runners } };
}
export function setselectedRunners(runners: any) {
  return { type: SET_SELECTED_RUNNERS, payload: { runners } };
}
export function setselectedRunnersView(runners: any) {
  return { type: SET_SELECTED_RUNNERS_VIEW, payload: { runners } };
}
export function removeRunners() {
  return { type: REMOVE_ALL };
}
