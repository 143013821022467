import React, { useEffect, useRef, useState } from "react";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
} from "@mui/material";

import { Dropdown } from "@mui/base/Dropdown";
import { Menu, MenuListboxSlotProps } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";

import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import { OrderPaymentMethodEnum } from "../../../types/orderPaymentMethodEnum";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { Avatar, Modal } from "antd";
import userRun from "../icons/run.png";
import timerLate from "../icons/late.png";
import pngContanti from "../icons/contanti.png";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import WarningIcon from "@mui/icons-material/Warning";
import axios from "axios";
import { changeeShowStore, setStoreOrders } from "../../../store/showStores";
import { DateTime } from "luxon";
import { height, margin, padding } from "@mui/system";
import { chnageActive } from "../../../store/showHelp";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
    padding: "4px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface IMyProps {
  access_token: string;
  showDirection: any;
}

const StoreOrders: React.FC<IMyProps> = (props: any) => {
  const showStores = useSelector((state: RootState) => state.showStores);
  const showRunners = useSelector((state: RootState) => state.showRunners);
  const [roundValue, setRoundValue] = useState(1);
  const [luggageSettings, setLuggageSettings] = useState<any[]>([]);
  const dispatchRedux = useDispatch();
  const { confirm } = Modal;
  useEffect(() => {
    let interval: any;

    fetch(`${process.env.REACT_APP_API}/setting/getCurrent`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.access_token}`,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setRoundValue(resp.data?.time_round_ritiro || 1);
      });

    return () => clearInterval(interval);
  }, []);

  const createHandleMenuClick = (menuItem: any, ord: any) => {
    return () => {
      assegnaRunner(menuItem, ord);
      console.log(`Clicked on ${JSON.stringify(menuItem.users)}`);
    };
  };

  const assegnaRunner = async (runner: any, record: any) => {
    confirm({
      title: ` Assegna ${runner.users.fullname}`,
      icon: <WarningIcon />,
      content: `${
        (record as any)?.runner_orders.length
          ? "L'ordine è già stato preso in carico da un'altro runner?"
          : ` Assegnare il runner all'ordine?`
      }`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        let response: any;
        if ((record as any)?.runner_orders[0]?.id) {
          response = await axios
            .patch(
              `${process.env.REACT_APP_API}/food/runner/order/${
                (record as any)?.runner_orders[0]?.id
              }`,
              {
                runner: {
                  user_id: runner.users.id,
                  payment_status: "pagamento controllato",
                },
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${props.access_token}`,
                },
              }
            )
            .then((res) => res.data);
        } else {
          response = await axios
            .post(
              `${process.env.REACT_APP_API}/food/runner/order`,
              {
                runner: {
                  order_id: record?.id,
                  user_id: runner.users.id,
                  gain: 0,
                  rimborso: 0,
                  payment_status: "pagamento controllato",
                },
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${props.access_token}`,
                },
              }
            )
            .then((res) => res.data);
        }
        if (response?.data) {
          const today = DateTime.local().toFormat("yyyy-MM-dd");
          getOrders({
            date_from: today,
            date_to: today,
            accessToken: props.access_token,
          });
        }
      },
      onCancel: () => false,
    });
  };

  async function getOrders({ date_from, date_to, accessToken }: any) {
    if (accessToken) {
      const urlParams = new URLSearchParams({ date_from, date_to });
      const url = `${
        process.env.REACT_APP_API
      }/food/order/?${urlParams.toString()}`;

      const response: any = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("response", response);

      if (!response.ok) {
        console.log("Network response was not ok " + response.statusText);
        if (response.status == 401) {
          // setTokenIvalid(true);
        }
      } else {
        const responseData = await response.json();
        const needsHelpOrders = responseData.data?.filter((ord: any) => {
          return ord?.help_requests.length > 0;
        });
        const needsHelp = needsHelpOrders.map((ordWithHelp: any) => {
          return ordWithHelp?.help_requests;
        });

        if (needsHelp.length > 0) {
          const helpNotmet = needsHelp[0].filter((help: any) => {
            return help?.served === false;
          });
          if (helpNotmet.length > 0) {
            dispatchRedux(chnageActive(true));
          }
        } else {
          dispatchRedux(chnageActive(false));
        }
        const ordersForStore = responseData.data.filter((ord: any) => {
          return ord.store_id === showStores.store_to_show.id;
        });
        dispatchRedux(setStoreOrders(ordersForStore));
      }
    }
  }

  const getDateWithoutTimeZone = (time: any): any => {
    if (time instanceof Date) {
      time = time.toLocaleString().replace(".000Z", "");
    }
    return new Date(new Date(time.replace(".000Z", "")).getTime());
  };

  const getDateWithoutTimeZoneDb = (time: string | Date) => {
    if (time instanceof Date) {
      time = time.toLocaleString().replace(".000Z", "");
    }
    return new Date(
      new Date(time.replace(".000Z", "")).getTime() + 60 * 60 * 1000
    );
  };
  const difference2Parts = (milliseconds: any) => {
    const secs = Math.floor(Math.abs(milliseconds) / 1000);
    const mins = Math.floor(secs / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    const millisecs = Math.floor(Math.abs(milliseconds)) % 1000;
    const multiple = (term: string, n: number) =>
      n !== 1 ? `${n} ${term}` : `1 ${term}`;

    return {
      days: days,
      hours: hours % 24,
      hoursTotal: hours,
      minutesTotal: mins,
      minutes: mins % 60,
      seconds: secs % 60,
      secondsTotal: secs,
      milliSeconds: millisecs,
      get diffStr() {
        return `${multiple(`giorni`, this.days)}, ${multiple(
          `ore`,
          this.hours
        )}, ${multiple(`minuti`, this.minutes)} e ${multiple(
          `secondi`,
          this.seconds
        )}`;
      },
      get diffStrMs() {
        return `${this.diffStr.replace(` e`, `, `)} e ${multiple(
          `millisecondi`,
          this.milliSeconds
        )}`;
      },
    };
  };

  const getRunnerTimer = (value: any) => {
    if (value.status.name == "ritirato") {
      const pickup = (value.food_orders || value.smart_logistics_orders)
        ?.real_pickup_time;
      const delivery = (value.food_orders || value.smart_logistics_orders)
        ?.real_delivery_time;
      if (
        pickup &&
        delivery &&
        new Date().getTime() >= getDateWithoutTimeZone(pickup).getTime()
      ) {
        const tempoConsegna = difference2Parts(
          getDateWithoutTimeZone(delivery) - getDateWithoutTimeZone(pickup)
        );

        const timer = difference2Parts(
          (new Date() as any) - getDateWithoutTimeZone(pickup)
        );

        if (timer.minutesTotal > tempoConsegna.minutesTotal) {
          return <Avatar size={18} src={timerLate} key="late" />;
        } else {
          return (
            <span className="round badge-success">{timer.minutesTotal}</span>
          );
        }
      }
    }
  };

  const getRoundedDate = (minutes: number, d = new Date(), d1: any = null) => {
    if (minutes <= 0) return d;
    const ms = 1000 * 60 * minutes; // convert minutes to ms
    if (!d1) {
      const roundedDate = new Date(Math.round(d.getTime() / ms) * ms);
      return roundedDate;
    } else {
      const roundedDate = new Date(Math.round(d1.getTime() / ms) * ms);
      const round = (roundedDate.getTime() - d1.getTime()) / 1000 / 60;
      d.setMinutes(d.getMinutes() + round);
      return new Date(d);
    }
  };

  const ritiroConsegna = (order: any) => {
    const food = order.food_orders;
    const smart = order.smart_logistics_orders;
    if (!food && !smart) {
      return "";
    }
    let ritiro = (food || smart).real_pickup_time;
    let consegna = (food || smart).real_delivery_time;

    if (ritiro) {
      ritiro = getRoundedDate(roundValue, getDateWithoutTimeZone(ritiro))
        .toLocaleTimeString()
        .substring(0, 5);
    }
    if (consegna) {
      consegna = getRoundedDate(
        roundValue,
        getDateWithoutTimeZone(consegna),
        getDateWithoutTimeZone(
          ritiro ? (food || smart).real_pickup_time : consegna
        )
      )
        .toLocaleTimeString()
        .substring(0, 5);
    }

    if (smart) {
      if (smart.type == "luggage delivery") {
        const extra_info = (smart.extra_info || "").split(/\r?\n/);
        const luggageData = JSON.parse(
          extra_info[extra_info.length - 1] || "{}"
        );
        if (luggageData.orario) {
          let ora = +(luggageData.orario || "0000").substring(0, 2);
          let minuti = +(luggageData.orario || "0000").substring(2, 4);
          ritiro = `${String(ora).padStart(2, "0")}:${String(minuti).padStart(
            2,
            "0"
          )}`;
          const luggageSetting = luggageSettings.find(
            (s: { id: any }) => s.id === luggageData.arrival_id
          );
          if (luggageSetting) {
            ora += Math.floor(luggageSetting.travel_time / 60);
            minuti += luggageSetting.travel_time % 60;
          }
          consegna = `${String(ora).padStart(2, "0")}:${String(minuti).padStart(
            2,
            "0"
          )}`;
        } else {
          ritiro = "";
          consegna = "";
        }
      } else if (smart.type == "on demand") {
        let dateTime = getDateWithoutTimeZone(smart?.real_pickup_time);
        if (smart?.deliveryt_slo.includes("(tomorrow)")) {
          dateTime = new Date(dateTime.setDate(dateTime.getDate() + 1));
        }
        ritiro = smart?.pickupt_slo;
        consegna = dateTime?.toLocaleString().substring(0, 10);
      }
    }
    return `${ritiro} → ${consegna}`;
  };

  const getSlot = (order: any) => {
    const food = order.food_orders;
    const smart = order.smart_logistics_orders;
    if (!food && !smart) {
      if (order.table_orders) {
        return `${order.table_orders?.store_room_service?.start_time} - ${order.table_orders?.store_room_service?.end_time}`;
      }
      return "";
    }
    let consegna = (food || smart).delivery_time;
    let next = "";

    if (consegna) {
      consegna = getDateWithoutTimeZone(consegna)
        .toLocaleTimeString()
        .substring(0, 5);
      next = new Date(
        getDateWithoutTimeZone((food || smart).delivery_time).getTime() +
          30 * 60000
      )
        .toLocaleTimeString()
        .substring(0, 5);
    }
    if (smart) {
      if (smart.type == "checkin" || smart.type == "luggage delivery") {
        consegna = new Date(smart.check_in_date)
          .toLocaleTimeString()
          .substring(0, 5);
        next = new Date(smart.check_out_date)
          .toLocaleTimeString()
          .substring(0, 5);
      } else if (smart.type == "on demand") {
        consegna = smart?.pickupt_slo;
        next = smart?.deliveryt_slo.replace("(tomorrow)", "");
      }
    }

    return `${consegna} - ${next}`;
  };

  const getDirection = (ord: any) => {
    props.showDirection(ord);
  };

  const isRunnerActive = (runner: any) => {
    const start = new Date();
    start.setMinutes(start.getMinutes() - 30);
    const end = new Date();
    end.setMinutes(end.getMinutes() + 30);

    const realTime = `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`;
    const startTime = `${start.getHours()}:${start.getMinutes()}:${start.getSeconds()}`;
    const endTime = `${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`;

    const active = runner?.users?.runner_days?.find((rd: any) => {
      const start_range =
        rd?.saturation_days?.range_hour?.start_range?.split(/T|\./)[1];
      const end_range =
        rd?.saturation_days?.range_hour?.end_range?.split(/T|\./)[1];

      return (
        (start_range <= startTime || start_range <= realTime) &&
        (end_range >= endTime || end_range >= realTime)
      );
    });
    return active || runner?.users?.runner_orders?.length;
  };

  const [allTheOrders, setAllTheOrders] = useState<any[]>([]);

  const fetchedIdsRef = useRef<Set<number | string>>(new Set());

  useEffect(() => {
    if (showStores.storeOrders.length > 0) {
      showStores.storeOrders.forEach((ord: any) => {
        if (!fetchedIdsRef.current.has(ord.id)) {
          fetchedIdsRef.current.add(ord.id);

          axios
            .get(`${process.env.REACT_APP_API}/food/order/${ord.id}`, {
              headers: {
                Authorization: `Bearer ${props.access_token}`,
              },
            })
            .then((res) => {
              const eachOrder = res.data.data;
              setAllTheOrders((prev) => [...prev, eachOrder]);
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      });
    }
  }, [showStores.storeOrders, props.access_token]);

  useEffect(() => {
    console.log("allTheOrders", allTheOrders);
  }, [allTheOrders]);
  return (
    <div className="mt-2">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nr ordine</StyledTableCell>
              <StyledTableCell align="left">Cliente</StyledTableCell>
              <StyledTableCell align="left">Ritiro → Consegna</StyledTableCell>
              <StyledTableCell align="left">Slot</StyledTableCell>
              <StyledTableCell align="left">Indirizzo</StyledTableCell>
              <StyledTableCell align="left">Stato</StyledTableCell>
              <StyledTableCell align="left">Tipo di consegna</StyledTableCell>

              <StyledTableCell align="left">Totale</StyledTableCell>
              <StyledTableCell align="left">assign</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allTheOrders
              ?.sort((a: any, b: any) => {
                const timeA = new Date(
                  a.food_orders.real_pickup_time
                ).getTime();
                const timeB = new Date(
                  b.food_orders.real_pickup_time
                ).getTime();
                return timeA - timeB;
              })
              .map((ord: any) => {
                return (
                  <StyledTableRow key={ord.number}>
                    <StyledTableCell component="th" scope="row">
                      <span>
                        {ord.help_requests[0]?.method && (
                          <span
                            id="flashing"
                            style={{
                              display: "inline",
                              width: "10px",

                              backgroundColor: ord.help_requests[0]?.served
                                ? "#5fe711"
                                : "red",
                              animation: ord.help_requests[0]?.served
                                ? "none"
                                : "blinker 2s linear infinite",
                              marginRight: "5px",
                              height: "10px",
                              color: ord.help_requests[0]?.served
                                ? "green"
                                : "red",
                            }}
                            className={
                              "badge " +
                              (ord.help_requests[0]?.served
                                ? "badge-success"
                                : "flashing")
                            }
                          >
                            {"- "}
                          </span>
                        )}
                        <span>#{ord.number}</span>
                      </span>
                      {/* {ord.number} */}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {ord.users.name}
                      <span className="m-1">{ord.users.phone}</span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {ritiroConsegna(ord)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {getSlot(ord)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span>
                        {ord.food_orders?.delivery_address ||
                          ord.smart_logistics_orders?.arrival_address}
                        <span
                          className="cursor-pointer "
                          onClick={() => getDirection(ord)}
                        >
                          <AssistantDirectionIcon></AssistantDirectionIcon>
                        </span>
                      </span>
                    </StyledTableCell>{" "}
                    <StyledTableCell align="left">
                      <div style={{ display: "inline-flex" }}>
                        <span
                          style={{
                            ...ord.status.styles,
                            marginLeft: "10px",
                            whiteSpace: "nowrap",
                          }}
                          className="badge"
                        >
                          {ord.status.name}
                        </span>{" "}
                      </div>
                    </StyledTableCell>{" "}
                    <StyledTableCell align="left">
                      <Avatar size={18} src={userRun} key="avatar" />

                      <span key="text">
                        <span>
                          {ord.runner_walking_time < 8 && "⚡"}
                          {getRunnerTimer(ord)}
                          {ord.runner_orders[0]?.users?.lastname}{" "}
                          {ord.runner_orders[0]?.users?.name}
                          {ord.runner_orders[0]?.cash_collectedu >=
                            ord.runner_orders[0]?.cash_limitu &&
                          ord.runner_orders[0]?.cash_limitu > 0 ? (
                            <Avatar size={26} src={pngContanti} key="late" />
                          ) : (
                            ""
                          )}
                        </span>
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className="flex items-center align-middle">
                        <span>
                          €
                          {Math.round(
                            (ord.total - ord.coupon_discount ?? 0) * 100
                          ) / 100}{" "}
                        </span>
                        {ord.payment_method ===
                        OrderPaymentMethodEnum.CreditCard ? (
                          <CreditCardIcon></CreditCardIcon>
                        ) : (
                          ord.payment_method ===
                            OrderPaymentMethodEnum.Cash && (
                            <FontAwesomeIcon icon={faSackDollar} />
                          )
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Dropdown>
                        <MenuButton disabled={ord.status.name !== "nuovo"}>
                          Select
                        </MenuButton>
                        <Menu slots={{ listbox: AnimatedListbox }}>
                          {showRunners.available_runners
                            .filter(isRunnerActive)
                            .map((runner: any, index: number) => {
                              // const [runnerId, runs]: any =
                              //   Object.entries(runner)[0];
                              // console.log("runs", runs);
                              // const activeRuns = runs.filter(isRunnerActive);

                              // runner.sort((a: any, b: any) => {
                              //   return (
                              //     new Date(b.created_at).getTime() -
                              //     new Date(a.created_at).getTime()
                              //   );
                              // });

                              return (
                                <div>
                                  <MenuItem
                                    disabled={ord.status.name !== "nuovo"}
                                    onClick={createHandleMenuClick(runner, ord)}
                                  >
                                    {runner?.users?.fullname}
                                  </MenuItem>
                                </div>
                              );
                            })}
                        </Menu>
                      </Dropdown>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StoreOrders;

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E6",
  700: "#0059B3",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  z-index: 1;

  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `
);

const AnimatedListbox = React.forwardRef(function AnimatedListbox(
  props: MenuListboxSlotProps,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
    );
  }

  const verticalPlacement = popupContext.placement.split("-")[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }
  `
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 2px 8px;
  border-radius: 8px;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }
  `
);
