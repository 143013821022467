import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { actvReducer } from "./actv/actvReducer";
import { showStores } from "./showStores";
import { showRunners } from "./showRunners";
import { showHelps } from "./showHelp";

export const rootReducer = combineReducers({
  actvReducer: actvReducer,
  showStores: showStores,
  showRunners: showRunners,
  showHelps: showHelps,
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export type RootState = ReturnType<typeof rootReducer>;
