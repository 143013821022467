import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useSendMapAction from "../../../hooks/useSendMapAction";
// import { EMapType, NotificationType } from "../mapBox";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import BottomPanelMap from "../bottomSheetMap/BottomPanel";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  changeStop,
  drawLine,
  updateTimeTables,
} from "../../../store/actv/actvReducer";
// import { useTranslate } from '@pankod/refine-core';
import DirectionsIcon from "@mui/icons-material/Directions";
// import {
//   Button,
//   Dropdown,
//   Menu,
//   notification,
//   Space,
// } from '@pankod/refine-antd';
// import Loading from '../Loading';
// import { DownOutlined } from '@ant-design/icons';
import { string } from "prop-types";
import "./styles.css";
import { EMapType } from "../../interfaces";

function ActvNavigation(props: any) {
  const sendMapAction = useSendMapAction();
  const dispatchRedux = useDispatch();
  // const t = useTranslate();
  const actvMode = useSelector((state: RootState) => state.actvReducer);
  const contentRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(true);
  const handleMakeIsAtTop = (isTop: boolean) => {
    setIsAtTop(isTop);
  };

  const [unclustred, setUnclustred] = useState([]);
  const [selectedUncluster, setSelectedUncluster] = useState<any>(null);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDir, setShowDir] = useState(false);
  const [todayTimetables, setTodayTimetables] = useState<any>();
  const [userLocation, setUserLocation] = useState<any>();
  const [currentLine, setCurrentLine] = useState<any>();
  const [currentRemainingTime, setCurrentRemaining] = useState<any>();

  const [allLinesName, setAllLinesName] = useState<any>([]);
  const [alltimeTables, setAlltimeTables] = useState<any>([]);
  const [selected, setSelected] = useState<any>();

  // const openNotificationWithIcon = (
  //   type: NotificationType,
  //   message?: string,
  //   desc?: string,
  // ) => {
  //   notification[type]({
  //     message: message ? message : t(''),
  //     description: desc ? desc : t(''),
  //   });
  // };

  const [actvMarkers, setActvMarkers] = useState<any>([]);

  const [showExactStop, setExactStop] = useState(false);

  const [isOpen, setOpen] = useState(false);
  const handleScroll = (e: any) => {
    if (e.target.scrollTop === 0) {
      handleMakeIsAtTop(true);
    } else {
      handleMakeIsAtTop(false);
    }
  };

  const calculateRemainingTime = (scheduledTime: any) => {
    if (scheduledTime) {
      const [scheduledHour, scheduledMinute] = scheduledTime
        .split(":")
        .map(Number);
      const currentTime: any = new Date();
      const scheduledDate: any = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        scheduledHour,
        scheduledMinute
      );

      // Calculate remaining time in minutes
      const timeDifference = scheduledDate - currentTime;
      const remainingMinutes = Math.floor(timeDifference / (1000 * 60));

      return remainingMinutes < 0
        ? remainingMinutes
        : remainingMinutes < 1
        ? ""
        : remainingMinutes;
    }
  };

  // Function to sort coordinates using the Greedy Nearest Neighbor approach
  // const sortCoordinates = (
  //   coordinates: [number, number][],
  // ): [number, number][] => {
  //   if (coordinates.length <= 1) return coordinates; // No need to sort if there is one or no point

  //   const sortedCoordinates: [number, number][] = [];
  //   const remainingCoordinates = [...coordinates];

  //   // Start with the first point (you can start with any point)
  //   let currentPoint = remainingCoordinates.shift()!;
  //   sortedCoordinates.push(currentPoint);

  //   while (remainingCoordinates.length > 0) {
  //     // Find the closest point to the current point
  //     let closestIndex = 0;
  //     let minDistance = calculateDistance(
  //       currentPoint,
  //       remainingCoordinates[0],
  //     );

  //     for (let i = 1; i < remainingCoordinates.length; i++) {
  //       const distance = calculateDistance(
  //         currentPoint,
  //         remainingCoordinates[i],
  //       );
  //       if (distance < minDistance) {
  //         minDistance = distance;
  //         closestIndex = i;
  //       }
  //     }

  //     // Add the closest point to the sorted list
  //     currentPoint = remainingCoordinates.splice(closestIndex, 1)[0];
  //     sortedCoordinates.push(currentPoint);
  //   }

  //   return sortedCoordinates;
  // };

  const findClosestItem = (
    selectedItem: { timetable: { timeMillis?: any; headsign?: any } },
    data: any[]
  ) => {
    const { headsign } = selectedItem.timetable;
    const filteredData = data?.filter(
      (item: { timetable: { headsign: any } }) =>
        item.timetable.headsign === headsign && item !== selectedItem
    );

    return filteredData.length > 0
      ? filteredData.reduce(
          (
            closest: { timetable: { timeMillis: number } },
            current: { timetable: { timeMillis: number } }
          ) => {
            return Math.abs(
              current.timetable.timeMillis - selectedItem.timetable.timeMillis
            ) <
              Math.abs(
                closest.timetable.timeMillis - selectedItem.timetable.timeMillis
              )
              ? current
              : closest;
          }
        )
      : null;
  };

  const handleShowRoute = async (
    marker: { timetable: { time: any }; shape: { file: any } },
    updateRedux: boolean
  ) => {
    try {
      const remainingTime = calculateRemainingTime(marker.timetable.time);

      dispatchRedux(drawLine(null));
      setCurrentRemaining(remainingTime);
      setCurrentLine(marker);

      const response = await axios.get(
        `${process.env.REACT_APP_ACTV_SERVER}${marker.shape.file}`
      );
      const data = response.data;

      if (updateRedux) {
        dispatchRedux(drawLine({ line: data, mm: marker }));
      }

      return data; // Ensure data is returned regardless of `updateRedux`
    } catch (error) {
      console.error("Error fetching data", error);
      throw error; // Propagate error if needed
    }
  };
  useEffect(() => {
    const remainingTime = calculateRemainingTime(
      actvMode.drawLine?.mm.timetable.time
    );

    if (remainingTime && remainingTime < 0) {
      const closestItem = findClosestItem(
        actvMode.drawLine?.mm,
        actvMode.timeTables ?? []
      );
      if (closestItem) {
        const remainingTimeNew = calculateRemainingTime(
          closestItem?.timetable?.time
        );
        console.log("the markers", closestItem);
        setCurrentLine(closestItem);
        setCurrentRemaining(remainingTimeNew);
      }
    } else {
      setCurrentRemaining(remainingTime);
      setCurrentLine(actvMode.drawLine?.mm);
    }
  }, [actvMode.actvModeOn]);

  useEffect(() => {
    try {
      const getMarkers = async () => {
        await axios
          .get(`${process.env.REACT_APP_ACTV_SERVER}/map/landingStage`)
          .then((res: any) => {
            setActvMarkers(res.data);
          });
      };

      getMarkers();
    } catch (error) {
      console.log("error", error);
    }
  }, [actvMode.actvModeOn]);

  const getAllTimetables = async (
    IDs: any[],
    targetHeadsign: string,
    userLat: any,
    userLon: any,
    maxDistance: number
  ) => {
    try {
      // Helper function to divide IDs into chunks of 10
      const chunkArray = (arr: string | any[], size: number) => {
        const result: any = [];
        for (let i = 0; i < arr.length; i += size) {
          result.push(arr.slice(i, i + size));
        }
        return result;
      };

      // Divide IDs into chunks of 10
      const idChunks = chunkArray(IDs, 10);

      const allTimetables: any = [];

      if (todayTimetables) {
        const filteredData = todayTimetables.filter(
          (data: any) =>
            data !== null &&
            (data.timetable.headsign
              .toLowerCase()
              .includes(targetHeadsign.toLowerCase()) ||
              targetHeadsign
                .toLowerCase()
                .includes(data.timetable.headsign.toLowerCase()))

          // haversineDistance(
          //   userLat,
          //   userLon,
          //   data.marker.lat,
          //   data.marker.lon,
          // ) <= maxDistance,
        );

        allTimetables.push(...filteredData);
      } else {
        for (const chunk of idChunks) {
          try {
            const response = await axios.get(
              `${
                process.env.REACT_APP_ACTV_SERVER
              }/timetable/by/landingStage/${chunk.join(",")}`
            );
            const filteredData = response.data.filter(
              (data: any) =>
                data !== null &&
                (data.timetable.headsign
                  .toLowerCase()
                  .includes(targetHeadsign.toLowerCase()) ||
                  targetHeadsign
                    .toLowerCase()
                    .includes(data.timetable.headsign.toLowerCase()))

              // haversineDistance(
              //   userLat,
              //   userLon,
              //   data.marker.lat,
              //   data.marker.lon,
              // ) <= maxDistance,
            );
            allTimetables.push(...filteredData); // Append results to the main array
          } catch (error) {
            console.error(`Failed to fetch timetable for IDs ${chunk}`, error);
          }
        }
      }
      // Sequentially fetch each chunk

      const sortedData = allTimetables.sort(
        (a: any, b: any) => a.timetable.timeMillis - b.timetable.timeMillis
      );
      setAlltimeTables(sortedData);
      dispatchRedux(updateTimeTables(sortedData));
      console.log("Combined timetables count:", sortedData, targetHeadsign);
      setLoading(false);
      // dispatchRedux(updateTimeTables(sortedData));
    } catch (error) {
      setLoading(false);
      // openNotificationWithIcon('error', 'something went wrong');
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      const geoOptions = {
        enableHighAccuracy: true,
        timeout: 10000, // Increased timeout to 10 seconds
        maximumAge: 0,
      };

      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({
            latitude: latitude,
            longitude: longitude,
          });
          console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
          // Clear watch after first successful location fetch
          navigator.geolocation.clearWatch(watchId);
        },
        (error) => {
          console.error("Error obtaining location:", error);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              // openNotificationWithIcon(
              //   'error',
              //   'You need to give permission to use Geolocation.',
              // );
              console.error("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.error("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.error("The request to get user location timed out.");
              break;
          }
        },
        geoOptions
      );

      // Cleanup on component unmount
      return () => navigator.geolocation.clearWatch(watchId);
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleShowDirection = async (destination: { name: string }) => {
    setShowDir(true);
    setLoading(true);
    const IDs = actvMarkers.map((actvs: any) => actvs.id);
    const userLat = userLocation?.latitude;
    const userLon = userLocation?.longitude;
    const maxDistance = 1000;

    getAllTimetables(
      IDs,
      destination.name.replace(/ "\w"$/, ""),
      userLat,
      userLon,
      maxDistance
    );
  };

  useEffect(() => {
    if (actvMarkers.length > 0) {
      const IDs = actvMarkers.map((actvs: any) => actvs.id);

      getTodayTimeTables(IDs);
    }
  }, [actvMarkers]);

  const getTodayTimeTables = async (allIds: any) => {
    const chunkArray = (arr: string | any[], size: number) => {
      const result: any = [];
      for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size));
      }
      return result;
    };

    // Divide IDs into chunks of 10
    const idChunks = chunkArray(allIds, 10);
    const allTimetables: any = [];
    for (const chunk of idChunks) {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_ACTV_SERVER
          }/timetable/by/landingStage/${chunk.join(",")}`
        );

        allTimetables.push(...response.data); // Append results to the main array
      } catch (error) {
        console.error(`Failed to fetch timetable for IDs ${chunk}`, error);
      }
    }

    setTodayTimetables(allTimetables);
  };

  useEffect(() => {
    if (actvMode.selectedStop?.id) {
      setShowDir(false);
    }
  }, [actvMode.selectedStop?.id]);

  const [relatedStops, setRelatedStops] = useState([]);

  // Function to calculate the distance between two coordinates using the Haversine formula
  const haversineDistance = (
    coord1: [number, number],
    coord2: [number, number]
  ): number => {
    const [lon1, lat1] = coord1;
    const [lon2, lat2] = coord2;

    const R = 6371e3; // Radius of Earth in meters
    const toRadians = (degrees: number) => (degrees * Math.PI) / 180;

    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δφ = toRadians(lat2 - lat1);
    const Δλ = toRadians(lon2 - lon1);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  useEffect(() => {
    if (showDir && actvMode.timeTables) {
      const getallLines = async () => {
        const data = await Promise.all(
          actvMode.timeTables?.map(async (element: any) => {
            const response = await handleShowRoute(element, false);
            console.log("response", response);

            return { line: response, mm: element };
          })
        );
        console.log("data.length", data);
        filterAllLineNames(data);
        // setAllLines(data);
      };
      getallLines();
    }
  }, [showDir, alltimeTables]);

  const filterAllLineNames = (allLines: string | any[]) => {
    if (allLines.length > 0) {
      for (let i = 0; i < allLines?.length; i++) {
        const line = allLines[i];

        console.log("line", line);

        const optimizedFilteredByRoute = actvMarkers?.filter((marker: any) => {
          const markerCoords: [number, number] = [marker.lon, marker.lat];
          const segmentCoordinates = line.line;

          for (const lineCoord of segmentCoordinates ?? []) {
            const distance = haversineDistance(markerCoords, lineCoord);
            if (distance <= 920) return true;
          }

          return false;
        });
        setAllNamesRelated(optimizedFilteredByRoute);
        // setAllLinesName(optimizedFilteredByRoute);
      }
    } else {
      setSelected({ label: props.stop?.name, key: props.stop?.id });
    }
  };
  const setAllNamesRelated = (relatedStop: any[]) => {
    const uniqueStops = Array.from(
      new Map(relatedStop.map((item) => [item.id, item])).values()
    );

    const forDropdown = uniqueStops.map((name) => ({
      key: name.id,
      label: name.name,
      marker: name,
    }));

    setAllLinesName(forDropdown);
  };

  const handleMenuClick = (e: { key: any }) => {
    const singleItem = allLinesName.find(
      (item: { key: any }) => String(item.key) == String(e.key)
    );

    setSelected(singleItem || props.stop?.name);
    updateTimeTablesNew(singleItem);
  };

  const updateTimeTablesNew = (item_to_update: any) => {
    try {
      const clusteredActv = actvMarkers.filter((Marker: any) => {
        return Marker.name
          .toLowerCase()
          .includes(
            actvMode?.selectedStop?.name.replace(/ "\w"$/, "").toLowerCase()
          );
      });

      if (clusteredActv.length > 0) {
        const IDs = clusteredActv.map((actvs: any) => {
          return actvs.id;
        });

        updateNewTimetables(IDs, item_to_update);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateNewTimetables = async (IDs: any[], item_to_update: any) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_ACTV_SERVER}/timetable/by/landingStage/${IDs}`
        )
        .then((res) => {
          const timetables = res.data
            .filter((data: any) => data !== null)
            .flat(); // Filter out failed requests

          const sortedData = timetables.sort(
            (a: any, b: any) => a.timetable.timeMillis - b.timetable.timeMillis
          );
          sendMapAction({
            type: EMapType.actvMode,
            actv_markers: sortedData,
          });

          // dispatchRedux(drawLine(null));
          // dispatchRedux(changeStop(item_to_update.marker));

          dispatchRedux(updateTimeTables(sortedData));
        })
        .catch((error) => {
          console.error(`Failed to fetch timetable for ID ${IDs}`, error);
          return null; // Return null for failed requests
        });
      setShowDir(false);
      setAllLinesName([]);
      // Dispatch all timetables at once
    } catch (error) {
      console.error("An error occurred while fetching timetables", error);
    }
  };

  // const menu = (
  //   <Menu
  //     style={{
  //       maxHeight: "250px", // Restrict the maximum height
  //       overflowY: "auto", // Enable vertical scrolling
  //     }}
  //     items={allLinesName}
  //     onClick={handleMenuClick}
  //   />
  // );

  const unclusterItems = () => {
    const clusteredActv = actvMarkers.filter((Marker: any) => {
      return Marker.name
        .toLowerCase()
        .includes(
          actvMode.selectedStop.name.replace(/ "\w"$/, "").toLowerCase()
        );
    });
    // Remove redundant ones by `name`
    const uniqueClusteredActv: any = [];
    const seenNames = new Set();

    clusteredActv.forEach((Marker: { name: unknown }) => {
      if (!seenNames.has(Marker.name)) {
        seenNames.add(Marker.name);
        uniqueClusteredActv.push(Marker);
      }
    });
    setShowAll(false);
    setUnclustred(uniqueClusteredActv);
  };

  useEffect(() => {
    if (actvMode?.selectedStop?.id) unclusterItems();
  }, [actvMode?.selectedStop?.id]);

  const itemsToShow = showAll
    ? actvMode.timeTables
    : actvMode.timeTables?.slice(0, 10) || [];

  return (
    <div
      style={{ zIndex: 99999999999999, height: "100%", backgroundColor: "red" }}
    >
      <BottomPanelMap
        onclose={() => {
          setOpen(false);
          dispatchRedux(updateTimeTables(null));
          dispatchRedux(drawLine(null));
          dispatchRedux(changeStop(null));
        }}
        isAtTop={isAtTop}
        contentRef={contentRef}
        showClose={true}
        storeSowing={true}
        heading={
          <div className="flex gap-[10px] justfy-center items-center">
            <h2 className="text-l font-semibold text-gray-800 ml-6">
              {showExactStop
                ? actvMode?.selectedStop?.name
                : actvMode?.selectedStop?.name.replace(/ "\w"$/, "")}
            </h2>
            <div className="flex gap-[10px] justfy-center items-center overflow-x-scroll scrollbar-hide ">
              {unclustred &&
                unclustred.length > 0 &&
                unclustred.map((cluster: any) => {
                  return (
                    <div
                      onClick={() => {
                        if (selectedUncluster === cluster.id) {
                          setSelectedUncluster(null);
                          updateTimeTablesNew(cluster);
                        } else {
                          updateNewTimetables(cluster.id, cluster);
                          setSelectedUncluster(cluster.id);
                        }
                      }}
                      className={`${
                        selectedUncluster === cluster.id || !selectedUncluster
                          ? "bg-[orange] "
                          : ""
                      }  text-[black] rounded-2xl  pl-1 pr-1 text-s cursor-pointer hover:bg-orange-400`}
                      key={cluster.id}
                    >
                      <span style={{ fontSize: "12px" }}>
                        {cluster.name.replace(
                          actvMode?.selectedStop?.name.replace(/ "\w"$/, ""),
                          ""
                        )}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        }
        optionalHeading={
          currentLine ? (
            <div className="bg-gray-50 flex items-center gap-[20px] ml-8">
              {currentLine.marker.name?.match(/"(\w)"$/)?.[1] && (
                <p className=" text-gray-600">
                  {/* {`"${currentLine.marker.name?.match(/"(\w)"$/)?.[1]}"`} */}
                  {currentLine.marker.name}
                </p>
              )}
              <div className="">
                {currentLine.route.image && (
                  <img
                    src={`${process.env.REACT_APP_ACTV_SERVER}${currentLine.route.image}`}
                    alt={`Route ${currentLine.route.name}`}
                    className=" max-w-xs h-[40px]"
                  />
                )}
              </div>

              <p className="mt-1 text-gray-600">
                {`=> ${currentLine.timetable.headsign}`}
              </p>

              <p className="mt-1 text-gray-600">
                <AccessTimeIcon className="mb-0.5"> </AccessTimeIcon>

                {currentLine.timetable.time}
              </p>
              <div className="text-gray-500">
                <strong></strong>
                {currentRemainingTime < 0 ? (
                  "already passed"
                ) : currentRemainingTime > 60 ? (
                  <>
                    {Math.floor(currentRemainingTime / 60)}{" "}
                    {Math.floor(currentRemainingTime / 60) === 1
                      ? "hour"
                      : "hours"}{" "}
                    {currentRemainingTime % 60 > 0 &&
                      `${currentRemainingTime % 60} minutes`}
                  </>
                ) : currentRemainingTime === "" ? (
                  "                water_bus.now"
                ) : currentRemainingTime === 1 ? (
                  "1 minute"
                ) : (
                  `${currentRemainingTime} minutes`
                )}
              </div>
            </div>
          ) : null
        }
        initialPosition={props.timetable ? 0.6 : 1}
        isOpenPanel={isOpen}
        contentRef1={null}
        contentRef2={null}
      >
        {loading ? (
          <div className="h-24 mt-24">Loading ...</div>
        ) : (
          <>
            <div
              className="h-[100vh] overflow-y-scroll scrollbar-hidden pb-[600px]"
              onScroll={handleScroll}
              ref={contentRef}
            >
              {itemsToShow?.length > 0 ? (
                itemsToShow?.map((data: any, index: any) => {
                  const remainingTime = calculateRemainingTime(
                    data.timetable.time
                  ); // Calculate remaining time
                  return remainingTime && remainingTime < 0 ? (
                    <div key={index + data.marker.id}></div>
                  ) : (
                    <div
                      onClick={() => handleShowRoute(data, true)}
                      key={index + data.marker.id}
                      className="border border-gray-300 rounded-2xl p-1 m-2 bg-gray-50 shadow-md cursor-pointer"
                    >
                      <div className=" flex items-center gap-[14px]">
                        {data.marker.name?.match(/"(\w)"$/)?.[1] && (
                          <p className=" text-gray-600">
                            {/* {` "${data.marker.name?.match(/"(\w)"$/)?.[1]}"`} */}

                            {/* {data.marker.name} */}
                          </p>
                        )}

                        <div className="flex flex-col items-start justfiy-start">
                          {data.route.image && (
                            <img
                              src={`${process.env.REACT_APP_ACTV_SERVER}${data.route.image}`}
                              alt={`Route ${data.route.name}`}
                              className=" max-w-xs h-[40px]"
                            />
                          )}
                        </div>
                        <span>{data.marker.name}</span>
                        <p className="mt-1 text-gray-600">
                          {`=> ${data.timetable.headsign}`}
                        </p>

                        <p className="mt-1 text-gray-600">
                          <AccessTimeIcon className="mb-0.5"> </AccessTimeIcon>

                          {data.timetable.time}
                        </p>
                        <div className="text-gray-500">
                          <strong></strong>
                          {remainingTime && remainingTime > 60 ? (
                            <>
                              {Math.floor(remainingTime / 60)}{" "}
                              {Math.floor(remainingTime / 60) === 1
                                ? "hour"
                                : "hours"}{" "}
                              {remainingTime % 60 > 0 &&
                                `${remainingTime % 60} minutes`}
                            </>
                          ) : (
                            <>
                              {remainingTime === ""
                                ? "water_bus.now"
                                : remainingTime === 1
                                ? "1 minute"
                                : `${remainingTime} minutes`}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex items-center justify-center mt-8">
                  No Schedule
                </div>
              )}
              {!showAll && actvMode.timeTables?.length > 10 && (
                <div className="flex justify-center items-center mt-4">
                  <button
                    onClick={() => setShowAll(true)}
                    className="px-4 py-2 border rounded-xl bg-[orange] text-white hover:bg-orange-400"
                  >
                    Show All
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </BottomPanelMap>
    </div>
  );
}

export default ActvNavigation;
