import React, { useState, useEffect, useRef } from "react";
import Map, {
  Marker,
  Popup,
  Source,
  Layer,
  GeolocateControl,
} from "react-map-gl";
import GeocoderControl from "../../map/geocoder-control";
import Pin from "../../map/pin";
import CustomPin from "../../map/custom-pin";
import mapConstants from "../constants/mapConstants";
import {
  IMapProps,
  IAppPayload,
  MapEventType,
  EMapType,
  UserType,
} from "../interfaces";
import MapRunner from "./mapRunner";
import MapAdmin from "./mapAdmin";
import MapStore from "./mapStores";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import axios from "axios";
import {
  changeStop,
  drawLine,
  markersNavUpdate,
  markersNavUpdateRest,
  updateTimeTables,
} from "../../store/actv/actvReducer";
import { add, debounce, upperCase } from "lodash";
import { FeatureCollection, Geometry } from "geojson";
import { StoreSubcategoryEnum } from "../../types/stores/storeSubcategoryEnum";
import icons from "../../constants/icons";
import useIsClosed from "../../hooks/useIsClosed";
import { Store } from "../../interfaces";
import { DateTime } from "luxon";
import { setStoreOrders, storeToShow } from "../../store/showStores";
import { OrderStatusEnum } from "../constants/orderStatusEnum";
import { chnageActive } from "../../store/showHelp";
import mapboxgl from "mapbox-gl";
declare global {
  interface WindowEventMap {
    [MapEventType]: { detail: IAppPayload };
  }
}

export const mapImgConsts: any = {
  cibo: ["food", "drink", "shopping"],
  spesa: ["shopping", "grocieries", "drink"],
  acquisti: ["shopping", "grocieries", "drink", "food"],
  bevande: ["drink"],
  logistica: ["logistics"],
};

// valid subcategories for image
export const subcategories = [
  "asiatico",
  "burgers",
  "gastronomia",
  "mediterraneo",
  "sushi",
  "poke",
  "pizza",
  "carne",
  "dolciegelati",
  "cocktailkit",
  "beer_mug",
  "vino",
  "liquori",
  "cocktail",
  "softdrinks",
  "pescheria",
  "ortofrutta",
  "prodotticaseari",
  "panificati",
  "aromieprofumi",
  "macelleria",
  "salumi",
  "artigianato",
  "bodycare",
  "fiori",
  "petcare",
  "homebeauty",
  "utilities",
  "deposit",
  "event",
  "pill",
  "fountain",
  "experience",
];

const ReactMap: React.FC<IMapProps> = ({
  onReceiveData,
  removePath,
  onrecieveRunnerDetail,
  showDirectioAdmin,
  onrecieonReceiveToken,
}) => {
  const [forWho, setForWho] = useState<any>("");
  const [access_token, setAccess_token] = useState("");
  const [stores, setStores] = useState<any[]>([]);
  const [markers, setMarkers] = useState<any[]>([]);
  const [markersStore, setMarkersStore] = useState<any>();
  const [defaulMarkers, setDefaulMarkers] = useState<Store[]>([]);

  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [refresh, setRefresh] = useState(false);
  const [pathPoints, setPathPoints] = useState<any>(null);
  const [route, setRoute] = useState(null);
  const [steps, setSteps] = useState<any>(null);

  const mapRef = useRef<any>(null);
  const map = mapRef?.current?.getMap();
  const dispatchRedux = useDispatch();
  const actvMode = useSelector((state: RootState) => state.actvReducer);
  const [actvMarkers, setActvMarkers] = useState<any>([]);
  const [actvMarkersAll, setActvMarkersAll] = useState<any>([]);
  const [position, setPosition] = useState<any>({
    lat: mapConstants.defaultCoords.lat,
    lng: mapConstants.defaultCoords.lng,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const showStores = useSelector((state: RootState) => state.showStores);
  const [storesWithOrder, setStoresWithOrder] = useState<any>([]);
  const isClosed = useIsClosed();
  const [allOrders, setAllOrders] = useState([]);

  const [viewport, setViewport] = useState({
    longitude: 12.32609,
    latitude: 45.43708,
    zoom: 12.5,
    bearing: 0,
    pitch: 0,
  });
  const [data /*setData*/] = useState<any>({
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: [],
    },
  });

  const categories = [
    "asiatico",
    "burgers",
    "gastronomia",
    "mediterraneo",
    "sushi",
    "poke",
    "pizza",
    "carne",
    "dolciegelati",
    "cocktailkit",
    "birra",
    "vino",
    "liquoriedistillati",
    "cocktail",
    "softdrinks",
    "pescheria",
    "ortofrutta",
    "prodotti_caseari",
    "panificati",
    "aromieprofumi",
    "macelleria",
    "salumieaffettati",
    "artigianato",
    "bodycare",
    "fiori",
    "petcare",
    "homebeauty",
    "utilities",
  ];

  // handle for who to show the map based on param
  const url = new URL(window.location.href);

  const typeParam = url.searchParams.get("type");
  useEffect(() => {
    if (typeParam === UserType.runner) {
      setForWho(UserType.runner);
    } else if (typeParam === UserType.admin) {
      setForWho(UserType.admin);
    } else if (typeParam === UserType.store) {
      setForWho(UserType.store);
    } else {
      console.log("no user type");
    }
  }, []);

  useEffect(() => {
    if (typeParam !== UserType.store && showStores.show_stores) {
      try {
        fetch(`${process.env.REACT_APP_API}/food/store`)
          .then((res) => res.json())
          .then((res) => {
            setStores(
              res.data.filter(
                (d: any) => checkLimits(d.latitude, d.longitude) && !isClosed(d)
              )
            );
            setDefaulMarkers(
              res.data.filter(
                (d: any) => checkLimits(d.latitude, d.longitude) && !isClosed(d)
              )
            );
          });
      } catch (error) {
        console.error("error", error);
      }
    }
    const appHandler: (messageData: {
      detail: IAppPayload;
    }) => void = (messageData: { detail: IAppPayload }) => {
      stores.length = 1;
      const data = messageData.detail;

      switch (data.type) {
        case EMapType.toLocation:
          break;
        case EMapType.filters:
          break;
        case EMapType.setPoints:
          break;
        case EMapType.removePoints:
          break;
        case EMapType.resetPoints:
          break;
        case EMapType.setPath:
          break;
        case EMapType.setStepByStep:
          break;
        case EMapType.clearMap:
          break;
        case EMapType.for_runner:
          break;
      }
    };
    window.addEventListener(MapEventType, appHandler);
    return () => {
      window.removeEventListener(MapEventType, appHandler);
    };
  }, [showStores.show_stores]);

  if (window.addEventListener) {
    window.addEventListener("message", function (event) {
      // Here, event.data contains the data sent from React Native.

      switch (event.data.type) {
        case EMapType.toLocation:
          break;
        case EMapType.filters:
          break;
        case EMapType.setPoints:
          setMarkers([
            ...markers,
            {
              latitude: event.data.data.lat,
              longitude: event.data.data.lng,
            },
          ]);
          setStores([]);
          break;
        case EMapType.removePoints:
          break;
        case EMapType.resetPoints:
          break;
        case EMapType.point_for_stores:
          setMarkersStore({
            latitude: event.data.data.lat,
            longitude: event.data.data.lng,
          });
          break;
        case EMapType.setPath:
          setStores([]);
          if (event?.data.data.steps) {
            onReceiveData(event?.data.data);
          }

          setPathPoints({
            startPoint: {
              latitude: event.data.data.lat,
              longitude: event.data.data.lng,
            },
            endPoint: {
              latitude: event.data.data.lat1,
              longitude: event.data.data.lng1,
            },
            showRoute: event?.data.data.steps ? false : true,
          });

          break;
        case EMapType.setStepByStep:
          break;
        case EMapType.clearMap:
          break;
        case EMapType.removePath:
          remove_shown_path();

          break;

        case EMapType.refresh:
          setRefresh(!refresh);
          setAccess_token(event.data.data.accessToken);
          onrecieonReceiveToken(event.data.data.accessToken);
          break;
        case EMapType.for_runner:
          setForWho(UserType.runner);
          setRefresh(!refresh);
          setAccess_token(event.data.data.accessToken);
          onrecieonReceiveToken(event.data.data.accessToken);

          break;
        case EMapType.for_admin:
          setForWho(UserType.admin);
          break;
      }
    });
  }

  const onClick = () => {
    // when implimenting onclick event disable click when forWho is store
    console.log("event");
  };

  const dataLayer: any = {
    id: "route",
    type: "line",
    source: "route",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#888",
      "line-width": 8,
    },
  };

  const checkLimits = (lat: number, lng: number) => {
    if (mapConstants.limits.maxLat < lat || mapConstants.limits.minLat > lat) {
      return false;
    }
    if (mapConstants.limits.maxLng < lng || mapConstants.limits.minLng > lng) {
      return false;
    }
    return true;
  };

  const Drag = (drag: any) => {
    setViewport({
      ...viewport,
      longitude: drag?.viewState?.longitude,
      latitude: drag?.viewState?.latitude,
      zoom: drag?.viewState?.zoom,
    });
  };
  const onZoom = (zoom: any) => {
    setViewport({
      ...viewport,
      longitude: zoom?.viewState?.longitude,
      latitude: zoom?.viewState?.latitude,
    });
  };

  const getDirections = async (
    start: [number, number],
    end: [number, number],
    showRoute: boolean
  ) => {
    const API_URL = `https://api.mapbox.com/directions/v5/mapbox/walking/${start[0]},${start[1]};${end[0]},${end[1]}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&geometries=geojson&exclude=ferry&banner_instructions=true&steps=true&voice_instructions=true`;
    const response = await fetch(API_URL);
    const data = await response.json();
    if (data && data?.routes[0]?.geometry) {
      setRoute(data?.routes[0]?.geometry);
      setSteps(data?.routes[0]);

      if (showRoute) {
        onReceiveData({
          steps: {
            distance: data?.routes[0].distance,
            duration: data?.routes[0].duration,
            from: "currentLocation",
          },
        });
      }
    } else {
      console.log(JSON.stringify(data));
    }

    setViewport({
      ...viewport,
      longitude: start[0],
      latitude: start[1],
    });
  };

  useEffect(() => {
    if (pathPoints !== null) {
      const start: [number, number] = [
        pathPoints.startPoint.longitude,
        pathPoints.startPoint.latitude,
      ];
      const end: [number, number] = [
        pathPoints.endPoint.longitude,
        pathPoints.endPoint.latitude,
      ];
      const showRoute = pathPoints.showRoute;
      getDirections(start, end, showRoute);
    }
  }, [pathPoints]);

  const showPath = (coord: any, showRoute?: boolean) => {
    setPathPoints({
      startPoint: {
        latitude: coord.lat,
        longitude: coord.lng,
      },
      endPoint: {
        latitude: coord.lat1,
        longitude: coord.lng1,
      },
      showRoute,
    });
  };

  useEffect(() => {
    if (removePath === true) {
      remove_shown_path();
    }
  }, [removePath]);

  const remove_shown_path = () => {
    setPathPoints(null);
    setRoute(null);
    setSteps(null);
    onReceiveData(null);
  };

  const geoControlRef = useRef<mapboxgl.GeolocateControl>(null);

  useEffect(() => {
    geoControlRef.current?.trigger();
  }, [geoControlRef.current]);

  function onGeolocate(event: any) {
    setViewport({
      ...viewport,
      latitude: event.coords?.latitude,
      longitude: event.coords?.longitude,
    });
  }
  useEffect(() => {
    setViewport({
      ...viewport,
      zoom: 15,
    });
    setViewport({
      ...viewport,
      zoom: 12.5,
    });
  }, [refresh]);

  // ********************* ACTV START**********************************//

  const [debouncedMarkers, setDebouncedMarkers] = useState(actvMarkers);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedMarkers(actvMarkers);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [actvMarkers]);

  useEffect(() => {
    const filteredActvMarkers = actvMarkersAll.filter((marker: any) => {
      return marker.name.toLowerCase().includes(actvMode.filters.toLowerCase());
    });

    if (actvMode.filters.length < 1) {
      setActvMarkers([...actvMarkersAll]);
    } else {
      setActvMarkers([...filteredActvMarkers]);
    }
  }, [actvMode.filters]);

  // Haversine formula to calculate distance between two lat/lon points
  const haversineDistanceOne = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in kilometers
  };

  // find closest items with unique base names
  const findClosestItems = (
    locations: any[],
    userLocation: { lat: any; lng: any },
    count = 10
  ) => {
    const seenNames = new Set();

    // Filter out locations with duplicate names after removing quoted suffixes
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const uniqueLocations = locations.filter((location) => {
      const nameWithoutSuffix = location.name.replace(/ "\w"$/, "");
      if (seenNames.has(nameWithoutSuffix)) {
        return false;
      } else {
        seenNames.add(nameWithoutSuffix);
        return true;
      }
    });

    return (
      uniqueLocations
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .map((location) => ({
          ...location,
          distance: haversineDistanceOne(
            userLocation.lat,
            userLocation.lng,
            location.lat,
            location.lon
          ),
        }))
        .sort((a, b) => a.distance - b.distance)
        .slice(0, count)
    );
  };

  useEffect(() => {
    try {
      const getMarkers = async () => {
        await axios
          .get(`${process.env.REACT_APP_ACTV_SERVER}/map/landingStage`)
          .then((res: any) => {
            setActvMarkers(res.data);
            setActvMarkersAll(res.data);

            const closestItems = findClosestItems(res.data, position);
            const closestIds = new Set(
              closestItems.map((item: any) => item.id)
            );

            const otherItems = res.data.filter(
              (marker: any) => !closestIds.has(marker.id)
            );
            const otherItemsFiltered = findClosestItems(
              otherItems,
              position,
              200
            );
            dispatchRedux(markersNavUpdateRest(otherItemsFiltered));

            dispatchRedux(markersNavUpdate(closestItems));
          })
          .catch((err) => {
            console.log("error fetching actv", err);
          });
      };

      getMarkers();
    } catch (error) {
      console.log("error", error);
    }
  }, [actvMode.actvModeOn]);

  const actvMarkersGeoJSON: any = {
    type: "FeatureCollection",
    features: debouncedMarkers.map((markerr: any) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [markerr.lon, markerr.lat],
      },
      properties: {
        id: markerr.id,
        sid: markerr.sid,
        name: markerr.name,
        lon: markerr.lon,
        lat: markerr.lat,
        icon: `${process.env.PUBLIC_URL}/images/cropedBoat.png`,

        markerData: markerr,
      },
    })),
  };

  useEffect(() => {
    if (map && !map.hasImage("boat")) {
      map.loadImage(
        `${process.env.PUBLIC_URL}/images/cropedBoat.png`,
        (error: any, image: any) => {
          if (error) {
            console.error("Failed to load the icon image", error);
            return;
          }
          if (image) {
            map.addImage("boat", image);
          }
        }
      );
    }
    if (map) {
      map.on("click", "actv-markers-layer", async (e: any) => {
        e.originalEvent.stopPropagation();
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["actv-markers-layer"],
        });
        const clickedFeature = features[0];
        dispatchRedux(drawLine(null));
        dispatchRedux(changeStop(clickedFeature.properties));
      });
    }
  }, [map]);

  const actvMarkerLayer: any = {
    id: "actv-markers-layer",
    type: "symbol",
    source: "actv-markers-source",
    layout: {
      "icon-image": "boat",
      // 'icon-size': 0.05,
      "icon-size": 0.1,
      "icon-anchor": "center",
    },
  };

  const [lineDataRoute, setlineDataRoute] =
    useState<FeatureCollection<Geometry>>();

  const [lineLayerRoute, setlineLayerRoute] = useState<any>();

  const startCoord: [number, number] = [
    actvMode.selectedStop?.lon,
    actvMode.selectedStop?.lat,
  ];

  // Function to calculate the distance between two coordinates using the Haversine formula
  const haversineDistance = (
    coord1: [number, number],
    coord2: [number, number]
  ): number => {
    const [lon1, lat1] = coord1;
    const [lon2, lat2] = coord2;

    const R = 6371e3; // Radius of Earth in meters
    const toRadians = (degrees: number) => (degrees * Math.PI) / 180;

    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δφ = toRadians(lat2 - lat1);
    const Δλ = toRadians(lon2 - lon1);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  useEffect(() => {
    if (actvMode?.drawLine?.line && actvMode.actvModeOn) {
      const destination = actvMarkersAll?.find((mm: any) => {
        return (
          upperCase(mm.name) ===
          upperCase(actvMode.drawLine?.mm?.timetable?.headsign)
        );
      });

      const findClosestIndex = (
        cords: [number, number][],
        isFisrt: boolean
      ): number => {
        if (!cords) return -1;

        let closestIndex = 0;
        let markerCoords: [number, number] = [
          actvMode.drawLine?.mm?.marker.lon,
          actvMode.drawLine?.mm?.marker.lat,
        ];

        if (!isFisrt && destination) {
          markerCoords = [destination.lon, destination.lat];
        }
        for (let i = 0; i < (actvMode?.drawLine?.line ?? []).length; i++) {
          const lineCoord = actvMode.drawLine.line[i];
          const distance = haversineDistance(markerCoords, lineCoord);

          if (distance <= 20) {
            closestIndex = i;
            console.log("found loc", lineCoord, "at index", closestIndex);
            break;
          }
        }

        return closestIndex;
      };

      const startIndex = findClosestIndex(actvMode?.drawLine?.line, true);
      const endIndex = findClosestIndex(actvMode?.drawLine?.line, false);

      const segmentCoordinates =
        actvMode.drawLine.line.slice(startIndex) ?? actvMode.drawLine.line;
      console.log("endIndex", segmentCoordinates.length);

      const optimizedFilteredByRoute = actvMarkersAll.filter((marker: any) => {
        const isQueryMatch =
          searchQuery.length > 2
            ? marker.name.toLowerCase().includes(searchQuery.toLowerCase())
            : true;

        if (!isQueryMatch) return false;

        const markerCoords: [number, number] = [marker.lon, marker.lat];

        for (const lineCoord of segmentCoordinates ?? []) {
          const distance = haversineDistance(markerCoords, lineCoord);
          if (distance <= 20) return true;
        }

        return false;
      });

      console.log("optimizedFilteredByRoute", optimizedFilteredByRoute);

      setActvMarkers(optimizedFilteredByRoute);

      // line data
      setlineDataRoute({
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: segmentCoordinates,
            },
            properties: {},
          },
        ],
      });

      // line layer
      setlineLayerRoute({
        id: "line-data",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": `#${actvMode?.drawLine?.mm?.route?.color}`,
          "line-width": 4,
        },
      });
    } else {
      setActvMarkers(actvMarkersAll);
    }
  }, [
    actvMode.actvModeOn,

    actvMode.selectedStop?.id,
    actvMode.drawLine?.mm?.route?.image,
  ]);

  useEffect(() => {
    if (actvMode.selectedStop) {
      const clusteredActv = actvMarkers.filter((marker: any) => {
        return marker.name
          .toLowerCase()
          .includes(
            actvMode.selectedStop.name.replace(/ "\w"$/, "").toLowerCase()
          );
      });

      const getAllTimetables = async (IDs: any[], dispatch: any) => {
        try {
          await axios
            .get(
              `${process.env.REACT_APP_ACTV_SERVER}/timetable/by/landingStage/${IDs}`
            )
            .then((res) => {
              const timetables = res.data
                .filter((data: any) => data !== null)
                .flat(); // Filter out failed requests

              const sortedData = timetables.sort(
                (a: any, b: any) =>
                  a.timetable.timeMillis - b.timetable.timeMillis
              );
              dispatch(updateTimeTables(sortedData));
            })
            .catch((error) => {
              console.error(`Failed to fetch timetable for ID ${IDs}`, error);
              return null; // Return null for failed requests
            });

          // Dispatch all timetables at once
        } catch (error) {
          console.error("An error occurred while fetching timetables", error);
        }
      };

      // Usage example
      if (clusteredActv.length > 0) {
        const IDs = clusteredActv.map((actvs: any) => {
          return actvs.id;
        });

        getAllTimetables(IDs, dispatchRedux);
      }
    } else if (actvMode.selectedStop?.id) {
      const getSingleTimetalbe = async () => {
        try {
          await axios
            .get(
              `${process.env.REACT_APP_ACTV_SERVER}/timetable/by/landingStage/${actvMode.selectedStop?.id}`
            )
            .then((res: any) => {
              dispatchRedux(updateTimeTables(res?.data));
            });
        } catch (error) {
          console.log("error", error);
        }
      };
      getSingleTimetalbe();
    }
  }, [actvMode.selectedStop?.id]);

  // ********************* ACTV END **********************************//

  const [markersGeoJSON, setmarkersGeoJSON] = useState<any>();

  useEffect(() => {
    setmarkersGeoJSON({
      type: "FeatureCollection",
      features: defaulMarkers.map((m: any) => {
        const hasOrder = storesWithOrder.some((ids: any) => {
          return m.id === ids;
        });
        const newOrder = allOrders.some((ords: any) => {
          return (
            ords.status.name === OrderStatusEnum.NUOVO ||
            ords.status.name === OrderStatusEnum.PRENOTATO_DAL_RUNNER
          );
        });

        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [m.longitude, m.latitude],
          },
          properties: {
            id: m.id,
            iconName: hasOrder ? `icon-hasOrder-${m.id}` : "icon-no-hasOrder",
            newOrder: newOrder && hasOrder,

            markerData: m,
            closed: m.isClosed,
            hasOrder: !hasOrder,
          },
        };
      }),
    });
  }, [defaulMarkers, storesWithOrder, showStores.show_stores, allOrders]);

  function mergeImageAndTextWithNewOrder(
    imageSrc: string,
    text: string,
    showIndicator: boolean,
    callback: (mergedImage: HTMLImageElement) => void
  ): void {
    const baseImage = new Image();
    baseImage.crossOrigin = "Anonymous";
    baseImage.src = imageSrc;

    baseImage.onload = () => {
      const baseHeight = baseImage.height;

      const fontSize = Math.floor(baseHeight / 5);
      const font = `bold ${fontSize}px Arial`;

      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");
      if (!tempCtx) {
        console.error("Could not get temporary canvas context.");
        return;
      }
      tempCtx.font = font;
      const textWidth = tempCtx.measureText(text).width;

      const padding = 30;

      const computedTextWidth = textWidth + padding;

      const radius = showIndicator ? 50 : 0; // Indicator circle radius.
      const indicatorMargin = 5; // Margin between text and indicator.
      const extraSide = showIndicator ? radius + indicatorMargin : 0; // Extra space on each side.
      const extraTop = showIndicator ? radius + indicatorMargin : 0; // Extra space on top.

      // Final canvas dimensions:
      const canvasWidth = computedTextWidth + 2 * extraSide;
      const canvasHeight = baseHeight + extraTop;

      // Create the final canvas.
      const canvas = document.createElement("canvas");
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        console.error("Could not get final canvas context.");
        return;
      }

      const imageXOffset =
        extraSide + (computedTextWidth - computedTextWidth) / 2;

      ctx.drawImage(
        baseImage,
        imageXOffset,
        extraTop,
        computedTextWidth,
        baseHeight
      );

      // Draw the text centered over the background image.
      const textX = imageXOffset + computedTextWidth / 2;
      const textY = extraTop + baseHeight / 2;
      ctx.font = font;
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(text, textX, textY);

      // draw the indicator which has new orders.
      if (showIndicator) {
        // Compute the text boundaries.
        const textLeft = textX - textWidth / 2;
        const textRight = textX + textWidth / 2;
        // Approximate the top of the text.
        const textTop = textY - fontSize / 2;
        // Position the circle so that its center is offset from the text’s top-right.
        let circleX = textRight + indicatorMargin + radius;
        let circleY = textTop - (indicatorMargin + radius);

        // Clamp circleX so the entire circle is within the canvas.
        if (circleX + radius > canvasWidth) {
          circleX = canvasWidth - radius - indicatorMargin;
        }
        // Ensure circleY is not above the canvas.
        if (circleY - radius < 0) {
          circleY = radius + indicatorMargin;
        }

        console.log("Drawing circle at:", {
          circleX,
          circleY,
          canvasWidth,
          canvasHeight,
        });

        ctx.beginPath();
        ctx.arc(circleX, circleY, radius, 0, 2 * Math.PI);
        ctx.fillStyle = "blue";
        ctx.fill();
        ctx.closePath();
      }

      // Convert the canvas to an image.
      const mergedImage = new Image();
      mergedImage.src = canvas.toDataURL("image/png");
      mergedImage.onload = () => callback(mergedImage);
    };

    baseImage.onerror = (error) => {
      console.error("Error loading base image:", error);
    };
  }

  useEffect(() => {
    if (map) {
      defaulMarkers.forEach((m: any) => {
        const hasOrder = storesWithOrder.some((id: any) => m.id === id);
        const newOrder = allOrders.some((ords: any) => {
          return (
            ords.store_id === m.id &&
            (ords.status.name === OrderStatusEnum.NUOVO ||
              ords.status.name === OrderStatusEnum.PRENOTATO_DAL_RUNNER)
          );
        });

        console.log("newOrder", newOrder);

        if (hasOrder) {
          const imageId = `icon-hasOrder-${m.id}`;

          mergeImageAndTextWithNewOrder(
            `${process.env.PUBLIC_URL}/images/rectangle1.png`,
            m.business_name,
            newOrder,
            (mergedImage) => {
              if (!map.hasImage(imageId)) {
                map.addImage(imageId, mergedImage);
              }
            }
          );

          // If newOrder is true, set up blinking.
          if (newOrder) {
            let showIndicator = true;
            setInterval(() => {
              showIndicator = !showIndicator;
              mergeImageAndTextWithNewOrder(
                `${process.env.PUBLIC_URL}/images/rectangle1.png`,
                m.business_name,
                showIndicator,
                (mergedImage) => {
                  if (map.hasImage(imageId)) {
                    try {
                      map.removeImage(imageId);
                    } catch (err) {
                      console.error("Error removing image:", err);
                    }
                  }
                  map.addImage(imageId, mergedImage);
                }
              );
            }, 1000);
          }
        } else {
          if (!map.hasImage("icon-no-hasOrder")) {
            map.loadImage(
              ` ${process.env.PUBLIC_URL}/images/circle.png`,
              (error: any, image: any) => {
                if (!error && image) {
                  map.addImage("icon-no-hasOrder", image);
                }
              }
            );
          }
        }
      });
    }
    if (map) {
      map.on("click", "markers-layer", async (e: any) => {
        e.originalEvent.stopPropagation();
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["markers-layer"],
        });
        if (features.length) {
          const clickedFeature = features[0];

          let markerData = clickedFeature.properties.markerData;
          console.log("Marker clicked:", markerData);

          console.log("markerData", markerData);
          if (typeof markerData === "string") {
            markerData = JSON.parse(markerData);
          }
          const ordersForStore = allOrders.filter((ord: any) => {
            return ord.store_id === markerData.id;
          });
          dispatchRedux(setStoreOrders(ordersForStore));
          console.log("ordersForStore", ordersForStore, allOrders);

          dispatchRedux(storeToShow(markerData));
        }
      });
      map.on("mouseenter", "markers-layer", async (e: any) => {
        e.originalEvent.stopPropagation();
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["markers-layer"],
        });
        if (features.length) {
          const clickedFeature = features[0];

          let markerData = clickedFeature.properties.markerData;
          console.log("Marker hovered:", markerData);
          setPopupInfo({
            type: "info",
            marker: JSON.parse(markerData),
          });
          console.log("markerData", markerData);
        }
      });
    }
  }, [map, allOrders, defaulMarkers]);

  const markerLayer: any = {
    id: "markers-layer",
    type: "symbol",
    source: "markers-source",
    layout: {
      "icon-image": ["get", "iconName"],
      "icon-size": [
        "case",
        ["==", ["get", "iconName"], "icon-no-hasOrder"],
        0.02,
        0.08,
      ],
      "icon-anchor": "bottom",
      "icon-allow-overlap": true,
    },

    paint: {
      "icon-opacity": ["case", ["boolean", ["get", "hasOrder"], false], 0.7, 1],
    },
  };

  useEffect(() => {
    console.log("markersGeoJSON", markersGeoJSON, markerLayer);
  }, [markersGeoJSON]);

  async function getOrders({ date_from, date_to, accessToken }: any) {
    if (accessToken) {
      const urlParams = new URLSearchParams({ date_from, date_to });
      const url = `${
        process.env.REACT_APP_API
      }/food/order/?${urlParams.toString()}`;

      const response: any = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("response", response);

      if (!response.ok) {
        console.log("Network response was not ok " + response.statusText);
        if (response.status == 401) {
        }
      } else {
        const responseData = await response.json();
        setAllOrders(responseData.data);
        setStoresWithOrder(
          responseData.data
            .filter((ord: any) => {
              return (
                ord.status.name !== OrderStatusEnum.CONSEGNATO &&
                ord.status.name !== OrderStatusEnum.RIFIUTATO_DA_COCAI &&
                ord.status.name !== OrderStatusEnum.RIFIUTATO_DAL_RISTORANTE
              );
            })
            .map((str: any) => {
              return str.store_id;
            })
        );

        console.log(responseData);
      }
    }
  }
  useEffect(() => {
    const today = DateTime.local().toFormat("yyyy-MM-dd");
    getOrders({
      date_from: today,
      date_to: today,
      accessToken: access_token,
    });
  }, [showStores.show_stores, access_token]);

  useEffect(() => {
    const needsHelpOrders = allOrders?.filter((ord: any) => {
      return ord?.help_requests.length > 0;
    });
    const needsHelp = needsHelpOrders.map((ordWithHelp: any) => {
      return ordWithHelp?.help_requests;
    });

    if (needsHelp.length > 0) {
      const helpNotmet = needsHelp[0].filter((helpss: any) => {
        return helpss?.served === false;
      });

      if (helpNotmet.length > 0) {
        dispatchRedux(chnageActive(true));
      }
    } else {
      dispatchRedux(chnageActive(false));
    }
  }, [allOrders, access_token]);

  return (
    <>
      <Map
        ref={mapRef}
        initialViewState={viewport}
        mapStyle={process.env.REACT_APP_MAP_STYLE}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        onClick={onClick}
        cursor={"pointer"}
        maxBounds={[
          mapConstants.bounds.southwest as [number, number],
          mapConstants.bounds.northeast as [number, number],
        ]}
        longitude={
          forWho === UserType.store
            ? markersStore?.longitude
              ? markersStore.longitude
              : viewport.longitude
            : viewport.longitude
        }
        latitude={
          forWho === UserType.store
            ? markersStore?.latitude
              ? markersStore?.latitude
              : viewport.latitude
            : viewport.latitude
        }
        onDrag={Drag}
        onZoom={onZoom}
        doubleClickZoom={true}
      >
        {actvMode.actvModeOn && (
          <Source
            id="actv-markers-source"
            type="geojson"
            data={actvMarkersGeoJSON}
          >
            <Layer {...actvMarkerLayer} />
          </Source>
        )}
        {/* {actvMode.selectedStop && actvMode.actvModeOn && (
          <Source id="circle-data" type="geojson" data={circleDataStop}>
            <Layer {...circleLayer} />
          </Source>
        )} */}
        {showStores.show_stores && (
          <Source id="markers-source" type="geojson" data={markersGeoJSON}>
            <Layer {...markerLayer} />
          </Source>
        )}

        {actvMode.drawLine?.mm?.marker?.id &&
          actvMode.selectedStop &&
          actvMode.actvModeOn && (
            <Source id="line-data" type="geojson" data={lineDataRoute}>
              <Layer {...lineLayerRoute} />
            </Source>
          )}
        {forWho === UserType.admin || forWho === "" ? (
          <GeocoderControl
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? ""}
            position="top-left"
            bbox={[12.243241, 45.41438, 12.414473, 45.46751]} /// limit the search to only venice
          />
        ) : (
          <div></div>
        )}
        {forWho === UserType.admin ? (
          <MapAdmin
            refresh={refresh}
            stores={stores}
            markers={markers}
            popupInfo={popupInfo}
            categories={categories}
            pathPoints={pathPoints}
            route={route}
            steps={steps}
            allOrders={allOrders}
            showpath={(coordinates: any, showRoute = false) =>
              showPath(coordinates, showRoute)
            }
            removePopUp={() => {
              setPopupInfo(null);
            }}
            onGeolocate={onGeolocate}
            access_token={access_token}
            onrecieveRunnerDetail={onrecieveRunnerDetail}
            showDirectioAdmin={showDirectioAdmin}
            onReceiveData={onReceiveData}
          ></MapAdmin>
        ) : forWho === UserType.store ? (
          <MapStore
            refresh={refresh}
            onReceiveData={onReceiveData}
            stores={stores}
            markers={markersStore}
            popupInfo={popupInfo}
            categories={categories}
            pathPoints={pathPoints}
            route={route}
            steps={steps}
            showpath={(coordinates: any, showRoute = false) =>
              showPath(coordinates, showRoute)
            }
            onGeolocate={onGeolocate}
            access_token={access_token}
          />
        ) : (
          forWho === UserType.runner && (
            <MapRunner
              refresh={refresh}
              onReceiveData={onReceiveData}
              stores={stores}
              markers={markers}
              popupInfo={popupInfo}
              categories={categories}
              pathPoints={pathPoints}
              route={route}
              steps={steps}
              showpath={(coordinates: any, showRoute = false) =>
                showPath(coordinates, showRoute)
              }
              onGeolocate={onGeolocate}
              access_token={access_token}
            ></MapRunner>
          )
        )}
      </Map>
    </>
  );
};

export default ReactMap;
