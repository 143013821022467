import { IAppPayload, MapEventType } from "../lib/interfaces";

const useSendMapAction = (): ((detail: IAppPayload) => void) => {
  const send = (detail: IAppPayload) => {
    const event = new CustomEvent<IAppPayload>(MapEventType, {
      detail,
    });
    window.dispatchEvent(event);
  };
  return send;
};

export default useSendMapAction;
