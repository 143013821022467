import React, { useEffect, useRef, useState } from "react";
import { Marker, Popup, Source, Layer, GeolocateControl } from "react-map-gl";
// import CustomPin from "../../map/custom-pin";
// import Pin from "../../map/pin";
import { DateTime } from "luxon";
import "./styles.css";
import { OrderStatusEnum } from "../constants/orderStatusEnum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { getDistance } from "../utils/getDistance";
import { Coords } from "../interfaces";

// interface Props{
//   Orders:any,
//   stores:any,
//   path:any,
// }
interface GetOrdersParams {
  date_from: string; // or Date, if applicable
  date_to: string; // or Date, if applicable
  accessToken: string;
}

const OrderIcons = (
  <FontAwesomeIcon icon={faLocationDot} bounce style={{ fontSize: 35 }} />
);
const StartLocationIcon = (
  <FontAwesomeIcon
    icon={faLocationDot}
    style={{ fontSize: 50, color: "orange" }}
  />
);
const DeliveryIcon = (
  <FontAwesomeIcon
    icon={faLocationDot}
    beat
    style={{ color: "#85d016", fontSize: 50 }}
  />
);
function MapRunner(props: any) {
  const [popupInfo, setPopupInfo] = useState<any>(null);
  // const [popupInfoPath, setPopupInfoPath] = useState<any>(null);
  const geoControlRef = useRef<mapboxgl.GeolocateControl>(null);
  const [showGeolocateControl, setShowGeolocateControl] = useState(true);
  const [currentLocation, setCurrentLocation] = useState<number[]>([]);
  const [currentPosId, setCurrentPosId] = useState<number>();
  const [location, setLocation] = useState<Coords>();
  const today = DateTime.local().toFormat("yyyy-MM-dd");
  const [Orders, setOrders] = useState([]);
  const [tokenInvalid, setTokenIvalid] = useState(false);

  // const [data /*setData*/] = useState<any>({
  //   type: "Feature",
  //   properties: {},
  //   geometry: {
  //     type: "LineString",
  //     coordinates: [],
  //   },
  // });

  const mapLayer: any = {
    id: "route",
    type: "line",
    source: "route",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "orange",
      "line-width": 5,
    },
  };

  useEffect(() => {
    // Activate as soon as the control is loaded
    geoControlRef.current?.trigger();
  }, [geoControlRef.current]);

  useEffect(() => {
    setTokenIvalid(false);
    getOrders({
      date_from: today,
      date_to: today,
      accessToken: props.access_token,
    });
    if (props.access_token && !currentPosId) {
      fetch(`${process.env.REACT_APP_API}/runner-position/my`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.access_token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.data?.id) {
            setCurrentPosId(res.data?.id);
          }
        });
    }
  }, [props.refresh]);

  useEffect(() => {
    if (!navigator.geolocation) {
      alert("Turn on Your location");
      return;
    }

    navigator?.permissions?.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        getLocation();
      }
    });

    function getLocation() {
      navigator.geolocation.watchPosition(
        (position) => {
          setCurrentLocation([
            position.coords.latitude,
            position.coords.longitude,
          ]);
          console.log("Latitude:", position.coords.latitude);
          console.log("Longitude:", position.coords.longitude);
          updateLocation();
        },
        (error) => {
          console.log(`Error Getting Location : ${error.message}`);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
  }, [navigator.geolocation]);

  useEffect(() => {
    sendPosition(props.access_token);
  }, [location?.lat, location?.lng, props.access_token]);

  async function sendPosition(accessToken: string) {
    if (accessToken && location && !tokenInvalid) {
      const url = `${process.env.REACT_APP_API}/runner-position/${
        currentPosId ? "/" + currentPosId : ""
      }`;

      const response = await fetch(url, {
        method: currentPosId ? "PATCH" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          runnerPosition: {
            latitude: location.lat,
            longitude: location.lng,
          },
        }),
      });

      if (!response.ok) {
        console.log("Network response was not ok " + response.statusText);
        if (response.status == 401) {
          setTokenIvalid(true);
        }
      } else {
        console.log("Runner position updated.");
      }
    }
  }

  async function getOrders({
    date_from,
    date_to,
    accessToken,
  }: GetOrdersParams) {
    if (accessToken && !tokenInvalid) {
      const urlParams = new URLSearchParams({ date_from, date_to });
      const url = `${
        process.env.REACT_APP_API
      }/food/order/?${urlParams.toString()}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        // throw new Error("Network response was not ok " + response.statusText);
        console.log("Network response was not ok " + response.statusText);
        if (response.status == 401) {
          setTokenIvalid(true);
        }
      } else {
        const responseData = await response.json();
        setOrders(responseData.data);
        console.log(responseData);
      }
    }
  }

  const updateLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const sendPos =
          !location ||
          getDistance(
            location.lat,
            location.lng,
            position.coords.latitude,
            position.coords.longitude
          ) >= 3;
        if (sendPos) {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          console.log("location:", location);
        }
        setTimeout(() => updateLocation(), 1000 * 30);
      },
      (error) => {
        console.log(`Error Getting Location : ${error.message}`);
        setTimeout(() => updateLocation(), 1000 * 60);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 2000,
      }
    );
  };

  const ShowPath = (orderToshow: any) => {
    let path_to_send = {
      lat: JSON.stringify(orderToshow.store.latitude),
      lng: JSON.stringify(orderToshow.store.longitude),
      lat1: JSON.stringify(orderToshow.food_orders)
        ? JSON.stringify(orderToshow.food_orders.latitude)
        : JSON.stringify(orderToshow.smart_logistics_orders)
        ? JSON.stringify(
            orderToshow.food_orders.smart_logistics_orders.latitude
          )
        : null,
      lng1: JSON.stringify(orderToshow.food_orders)
        ? JSON.stringify(orderToshow.food_orders.longitude)
        : JSON.stringify(orderToshow.smart_logistics_orders)
        ? JSON.stringify(
            orderToshow.food_orders.smart_logistics_orders.longitude
          )
        : null,
    };
    props.showpath(path_to_send);
    console.log(
      typeof JSON.stringify(orderToshow.runner_walking_time),
      typeof JSON.stringify(orderToshow.distance)
    );

    props.onReceiveData({
      steps: {
        duration: Number(JSON.stringify(orderToshow.runner_walking_time)),
        distance: JSON.stringify(orderToshow.distance),
      },
    });
    setPopupInfo(null);
  };

  const takeMetoStore = (store: any) => {
    const bounds = {
      minLat: 45.422991,
      maxLat: 45.502159,
      minLng: 12.300728,
      maxLng: 12.367236,
    };
    console.log("currentLocation", currentLocation);

    const [lat, lng] = currentLocation;

    if (
      lng >= bounds.minLng &&
      lng <= bounds.maxLng &&
      lat >= bounds.minLat &&
      lat <= bounds.maxLat
    ) {
      let path_to_send = {
        lat: currentLocation[0],
        lng: currentLocation[1],
        lat1: JSON.stringify(store.store.latitude),
        lng1: JSON.stringify(store.store.longitude),
      };
      props.showpath(path_to_send, true);
      setPopupInfo(null);
    } else {
      alert("You must be in Venice to show the path");
    }
  };

  // useEffect(() => {
  //   if (accessToken && !currentPosId) {
  //     fetch(`${Config.API_PREFIX}/runner-position/my`, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     })
  //       .then((res) => res.json())
  //       .then((res) => {
  //         if (res.data?.id) {
  //           setCurrentPosId(res.data?.id);
  //         }
  //       });
  //   }
  // }, [accessToken]);

  // async function sendPositionRunner(accessToken: string, location: any) {
  //   if (accessToken && location) {
  //     const url = `${Config.API_PREFIX}/runner-position/${
  //       currentPosId ? "/" + currentPosId : ""
  //     }`;

  //     const response = await fetch(url, {
  //       // method: currentPosId ? 'PATCH' : 'POST',
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //       body: JSON.stringify({
  //         runnerPosition: {
  //           latitude: location.latitude,
  //           longitude: location.longitude,
  //         },
  //       }),
  //     });

  //     if (!response.ok) {
  //       console.log("Network response was not ok " + response.statusText);
  //     } else {
  //       console.log("Runner position updated.");
  //     }
  //   }
  // }

  return (
    <div>
      {Orders.filter(
        (order: any) => order.status.name === OrderStatusEnum.NUOVO
      ).map((order: any, index: number) => (
        <Marker
          key={`marker-${index}`}
          longitude={order.store.longitude}
          latitude={order.store.latitude}
          anchor="bottom"
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            setPopupInfo(order);
          }}
        >
          {OrderIcons}
        </Marker>
      ))}
      {showGeolocateControl && (
        <GeolocateControl
          ref={geoControlRef}
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation={true}
          showAccuracyCircle={true}
          showUserLocation={true}
          onGeolocate={(e: any) => {
            if (e?.coords?.longitude && e?.coords?.latitude) {
              setLocation({
                lat: e?.coords?.latitude,
                lng: e?.coords?.longitude,
              });
              console.log("located", e.coords);
            }
          }}
          showUserHeading={true}
          onOutOfMaxBounds={() => {
            alert(
              "Show location only works in Venice , if You wish to work you need to go to Venice"
            );
            // setShowGeolocateControl(false);
          }}
          position="top-left"
        />
      )}
      {props.steps &&
        props.steps?.legs[0]?.steps &&
        props.steps?.legs[0]?.steps?.map((step: any, index: any) => (
          <Marker
            key={`step-${index}`}
            longitude={step.maneuver.location[0]}
            latitude={step.maneuver.location[1]}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();

              // setPopupInfoPath(step);
            }}
          >
            {index === 0 ? (
              StartLocationIcon
            ) : index === props.steps.legs[0].steps.length - 1 ? (
              DeliveryIcon
            ) : (
              <i
                className="fa fa-map-marker"
                title="Step"
                style={{ color: "red", fontSize: "1em", display: "none" }}
              ></i>
            )}
          </Marker>
        ))}
      {/* {popupInfoPath && (
        <Popup
          anchor="top"
          longitude={popupInfoPath.maneuver.location[0]}
          latitude={popupInfoPath.maneuver.location[1]}
          onClose={() => setPopupInfoPath(null)}
        >
          <div>{popupInfoPath.Instructions}</div>
        </Popup>
      )} */}
      {props.route && (
        <Source type="geojson" data={props.route}>
          <Layer {...mapLayer} />
        </Source>
      )}
      {popupInfo && (
        <Popup
          anchor="top"
          longitude={Number(popupInfo.store.longitude)}
          latitude={Number(popupInfo.store.latitude)}
          onClose={() => setPopupInfo(null)}
          className="custom-popup"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <span>Pick-up @ </span>
              <span className="popup_address_text">
                {popupInfo.store.district + ", " + popupInfo.store.civic_number}
              </span>
            </div>
            {/* {popupInfo.food_orders.shopping_cart.map} */}

            {/* <p>{popupInfo.food_orders.real_delivery_time}</p> */}
            <p>
              <span>deliver @ </span>
              <span className="popup_address_text">
                {popupInfo.food_orders
                  ? popupInfo.food_orders.delivery_address
                  : popupInfo.smart_logistics_orders
                  ? popupInfo.food_orders.smart_logistics_orders
                      .delivery_address
                  : ""}
              </span>
            </p>
            <button
              className="show_path_popup_btn"
              onClick={() => ShowPath(popupInfo)}
            >
              Show Direction
            </button>
            <button
              style={{ marginTop: "10px" }}
              className="show_path_popup_btn"
              onClick={() => takeMetoStore(popupInfo)}
            >
              Take me to store
            </button>
            {/* <img
            width="100%"
            src={`https://www.cocaiexpress.com/api/uploads/stores/${popupInfo.id}/${popupInfo.img_path}`}
          /> */}
          </div>
        </Popup>
      )}
    </div>
  );
}

export default MapRunner;
