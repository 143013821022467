import React, { useEffect, useRef, useState } from "react";
import ReactMap from "./lib/components/ReactMap";

import Collapsible from "react-collapsible";
import RunnerDetail from "./lib/components/runnerDetail";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import { changeActvMode } from "./store/actv/actvReducer";
import CloseIcon from "@mui/icons-material/Close";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ActvNavigation from "./lib/components/actv_navigation";
import "./App.css";
import { changeeShowStore, storeToShow } from "./store/showStores";
import { UserType } from "./lib/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonWalking } from "@fortawesome/free-solid-svg-icons";
import { changeShowRunners } from "./store/showRunners";
import BottomPanelMap from "./lib/components/bottomSheetMap/BottomPanel";
import StoreOrders from "./lib/components/storeOrders";
import { toggleShowHelps } from "./store/showHelp";

const RunnerIcons = (
  <FontAwesomeIcon
    icon={faPersonWalking}
    style={{ fontSize: 18, marginTop: "3px" }}
  />
);
function App() {
  const [steps, setSteps] = useState<any>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [remove_path, setremove_path] = useState(false);
  const [runnerDetailComp, setRunnerDetail] = useState<any>();
  const [showDirectionforAdmin, setshowDirectionforAdmin] = useState<any>();
  const dispatchRedux = useDispatch();
  const actvMode = useSelector((state: RootState) => state.actvReducer);
  const showStores = useSelector((state: RootState) => state.showStores);
  const showHelps = useSelector((state: RootState) => state.showHelps);

  const showRunners = useSelector((state: RootState) => state.showRunners);
  const [forWho, setForWho] = useState<any>("");
  const [isAtTop, setIsAtTop] = useState(true);
  const contentRef = useRef(null);
  const [isOpenStore, setOpenStore] = useState(false);
  const [access_token, setToken] = useState("");

  const handleScroll = (e: any) => {
    if (e.target.scrollTop === 0) {
      handleMakeIsAtTop(true);
    } else {
      handleMakeIsAtTop(false);
    }
  };
  const handleMakeIsAtTop = (isTop: boolean) => {
    setIsAtTop(isTop);
  };
  function handleMapData(data: any) {
    setSteps(data?.steps);
    if (data === null) {
      setremove_path(false);
    }
  }
  const removePath = () => {
    setremove_path(true);
    setshowDirectionforAdmin(null);
  };

  useEffect(() => {
    console.log("showRunners.selected_runners", showRunners.selected_runners);
    handleRunnerDetail(showRunners.selected_runners);
  }, [showRunners.selected_runners]);

  const handleRunnerDetail = (data: any) => {
    setRunnerDetail(
      <div className="runnerDetails">
        {showRunners.selected_runners.map((runner_data: any) => {
          return (
            <RunnerDetail
              key={runner_data.data.users.id}
              showDirection={showDirection}
              data={runner_data}
            />
          );
        })}
      </div>
    );
  };

  const showDirection = (param: any) => {
    setshowDirectionforAdmin(param);
  };

  const url = new URL(window.location.href);

  const typeParam = url.searchParams.get("type");
  useEffect(() => {
    if (typeParam === UserType.runner) {
      setForWho(UserType.runner);
    } else if (typeParam === UserType.admin) {
      setForWho(UserType.admin);
    } else if (typeParam === UserType.store) {
      setForWho(UserType.store);
    } else {
      console.log("no user type");
    }
  }, []);

  return (
    <>
      <ReactMap
        onrecieonReceiveToken={(token: any) => setToken(token)}
        onReceiveData={handleMapData}
        removePath={remove_path}
        onrecieveRunnerDetail={handleRunnerDetail}
        showDirectioAdmin={showDirectionforAdmin}
      />
      <div style={{ position: "absolute" }}>
        <div className="actv cursor-pointer">
          {actvMode.actvModeOn ? (
            <div
              onClick={() => {
                dispatchRedux(changeActvMode(false));
              }}
            >
              <CloseIcon className="m-1"></CloseIcon>
            </div>
          ) : (
            <img
              style={{ width: "20px", height: "20px" }}
              onClick={() => {
                dispatchRedux(changeActvMode(true));
              }}
              src={`${process.env.PUBLIC_URL}/images/home/boat-svgrepo-com.svg`}
            />
          )}
        </div>

        {forWho === UserType.admin && (
          <>
            <div className="helpRequest cursor-pointer">
              {showHelps.show_helps ? (
                <div
                  onClick={() => {
                    dispatchRedux(toggleShowHelps());
                  }}
                >
                  <CloseIcon className="m-1"></CloseIcon>
                </div>
              ) : (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <img
                    style={{ width: "20px", height: "20px" }}
                    onClick={() => dispatchRedux(toggleShowHelps())}
                    src={`${process.env.PUBLIC_URL}/images/help.jpeg`}
                    alt="help"
                  />
                  {/* The blinking dot */}
                  <span
                    style={{
                      display: showHelps.activeHelp ? "block" : "none",
                      position: "absolute",
                      top: 0,
                      right: 0,
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                      background: "red",
                      animation: showHelps.activeHelp
                        ? "blinker 2s linear infinite"
                        : "none",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="restaurants cursor-pointer">
              {showStores.show_stores ? (
                <div
                  onClick={() => {
                    dispatchRedux(changeeShowStore(false));
                  }}
                >
                  <CloseIcon className="m-1 "></CloseIcon>
                </div>
              ) : (
                <RestaurantIcon
                  onClick={() => {
                    dispatchRedux(changeeShowStore(true));
                  }}
                  className="m-1 "
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                ></RestaurantIcon>
              )}
            </div>
            <div
              onClick={() => {
                dispatchRedux(changeShowRunners(true));
              }}
              className="show_runners flex justify-center align-center cursor-pointer "
            >
              {showRunners.show_runners ? (
                <div>
                  <CloseIcon className="m-1 "></CloseIcon>
                </div>
              ) : (
                <div>{RunnerIcons}</div>
              )}
            </div>
          </>
        )}
        <div className="navigation">
          {showStores.store_to_show && (
            <BottomPanelMap
              onclose={() => {
                dispatchRedux(storeToShow(null));
              }}
              isAtTop={isAtTop}
              contentRef={contentRef}
              showClose={true}
              storeSowing={true}
              heading={
                <div className="flex gap-[10px] justfy-center items-center">
                  <h2 className="bold ml-2 text-l">
                    {showStores.store_to_show.business_name}
                  </h2>
                </div>
              }
              optionalHeading={null}
              initialPosition={0.7}
              isOpenPanel={isOpenStore}
              contentRef1={null}
              contentRef2={null}
            >
              <>
                <div
                  style={{ zIndex: 9999999999 }}
                  className="h-[100vh] overflow-y-scroll scrollbar-hidden pb-[600px]"
                  onScroll={handleScroll}
                  ref={contentRef}
                >
                  {" "}
                  <StoreOrders
                    key={showStores.store_to_show.business_name}
                    showDirection={showDirection}
                    access_token={access_token}
                  />
                </div>
              </>
            </BottomPanelMap>
          )}
        </div>

        {/* )} */}
        <div className="navigation">
          {actvMode.actvModeOn && (
            <div style={{ zIndex: 9999999999 }}>
              <div>
                <ActvNavigation
                  timetable={actvMode.timeTables}
                  stop={actvMode.selectedStop}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {runnerDetailComp}
      {steps && (
        <div className="infoBox flex  items-center gap-[10px]">
          <div onClick={() => setIsOpen(!isOpen)}>
            <div className="details-parts">
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "start",
                  // marginTop: 10,
                }}
              >
                {!steps.from ? (
                  <>
                    <p className="inside-text">
                      {steps?.distance >= 1000
                        ? `${(Number(steps?.distance) / 1000).toFixed(2)} KM`
                        : `${Number(steps?.distance)} M`}
                    </p>
                    <p className="inside-text">{steps?.duration} Minutes</p>
                  </>
                ) : (
                  <>
                    <p className="inside-text">
                      {steps?.distance >= 1000
                        ? `${(Number(steps?.distance) / 1000).toFixed(2)} KM`
                        : `${Number(steps?.distance).toFixed(0)} M`}
                    </p>
                    <p className="inside-text">
                      {(Number(steps?.duration) / 60).toFixed(0)} Minutes
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <button
            style={{ borderRadius: "50% " }}
            className="bg-[red] w-[20px] h-[20px] flex justify-center items-center"
            onClick={removePath}
          >
            <CloseIcon style={{ fontSize: "20px" }}> </CloseIcon>
          </button>
        </div>
      )}
    </>
  );
}

export default App;
export { ReactMap };
