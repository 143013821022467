import React, { Children, useEffect, useState, useRef } from "react";
import { useSpring, animated, to as springTo } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
// import {
//   change_store,
//   remove_current_store,
//   setInView,
//   setSameLocationStores,
//   setCloseLocationStores,
//   setFavAddress,
//   resetStoreState,
// } from "../../store/selectedStore/selectedStore";
// import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import "./stylemap.css";
import { RootState } from "../../../store";

interface BottomPanelMapProps {
  isOpenPanel?: any;
  children?: any;
  initialPosition?: any;
  onclose?: () => void;
  heading?: any;
  showClose?: any;
  storeSowing?: any;
  contentRef?: any;
  contentRef1?: any;
  contentRef2?: any;
  isAtTop?: any;
  optionalHeading?: any;
  headingStyle?: any;
  contentRef3?: any;
}

const BottomPanelMap: React.FC<BottomPanelMapProps> = ({
  isOpenPanel,
  children,
  initialPosition = 0.8,
  onclose,
  heading,
  showClose,
  storeSowing,
  contentRef,
  contentRef1,
  contentRef2,
  isAtTop,
  optionalHeading,
  headingStyle,
  contentRef3,
}) => {
  // const isMobile = useCheckMobileScreen();

  const SelectedStoreReducer = useSelector(
    (state: RootState) => state.SelectedStoreReducer
  );
  const actvMode = useSelector((state: RootState) => state.actvReducer);
  const [canender, setCanRender] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isatBottomState, setIsatBottomState] = useState(false);
  const isatBottom = useRef(isatBottomState);

  const [{ y }, api] = useSpring(() => ({
    y:
      window.innerHeight *
      (actvMode.drawLine?.mm?.route && actvMode.actvModeOn
        ? 0.9
        : initialPosition),
  }));

  const updateIsatBottom = (value) => {
    isatBottom.current = value;
    setIsatBottomState(value); // Update the state to trigger re-render
  };

  const dispatchRedux = useDispatch();

  const open = () => {
    setCanRender(true);
    api.start({ y: window.innerHeight * 0.47 });
    setIsOpen(true);
  };

  useEffect(() => {
    if (initialPosition !== 1) setCanRender(true);
  }, [initialPosition]);

  const close = () => {
    api.start({ y: window.innerHeight * 1 });
    setIsOpen(false);
    onclose();
    // dispatchRedux(resetStoreState());
  };

  const bind = useDrag(
    ({
      last,
      movement: [, my],
      memo = y.get(),
      velocity,
      direction: [, dy],
      event,
    }) => {
      const isInsideContent =
        contentRef?.current?.contains(event.target) ?? false;
      const isInsideContent1 =
        contentRef1?.current?.contains(event.target) ?? false;
      const isInsideContent2 =
        contentRef2?.current?.contains(event.target) ?? false;
      const isInsideContent3 =
        contentRef3?.current?.contains(event.target) ?? false;
      // console.log("isopen", isOpen);

      if (
        !isInsideContent &&
        !isInsideContent1 &&
        !isInsideContent2 &&
        !isInsideContent3
      ) {
        if (last) {
          const threshold = window.innerHeight * 0.25;

          if (
            dy > 0 &&
            memo >= window.innerHeight * initialPosition &&
            velocity > 0.5
          ) {
            updateIsatBottom(true);
            api.start({ y: window.innerHeight * 0.9 });
          } else if (
            (memo > threshold && dy < 0) ||
            (dy < 0 && velocity > 0.5)
          ) {
            if (dy < 0 && memo === window.innerHeight * initialPosition) {
              updateIsatBottom(false);
              open();
            } else {
              isatBottom.current = false;
              api.start({ y: window.innerHeight * initialPosition });
            }
          } else if (dy > 0 || (dy > 0 && velocity > 0.5)) {
            updateIsatBottom(false);
            api.start({ y: window.innerHeight * initialPosition });
            setIsOpen(false);
            if (!storeSowing) {
              setIsOpen(false);
              onclose();
            }
          } else {
            api.start({ y: memo, immediate: false }); // Snap back to memo position
          }
        } else {
          api.start({ y: memo + my, immediate: true });
        }
        return memo;
      } else if (isAtTop) {
        if (last) {
          const threshold = window.innerHeight * 0.15;
          if (
            dy > 0 &&
            memo >= window.innerHeight * initialPosition &&
            velocity > 0.5
          ) {
            updateIsatBottom(true);
            api.start({ y: window.innerHeight * 0.9 });
          } else if (
            (memo > threshold && dy < 0) ||
            (dy < 0 && velocity > 0.5)
          ) {
            updateIsatBottom(false);
            if (dy < 0 && memo === window.innerHeight * initialPosition) {
              open();
            } else {
              api.start({ y: window.innerHeight * initialPosition });
            }
          } else if (
            (memo < threshold && dy > 0) ||
            (dy > 0 && velocity > 0.5)
          ) {
            updateIsatBottom(false);
            api.start({ y: window.innerHeight * initialPosition });
            setIsOpen(false);
            if (!storeSowing) {
              setIsOpen(false);
              // onclose();
            }
          } else {
            api.start({ y: memo, immediate: false }); // Snap back to memo position
          }
        } else {
          api.start({ y: memo + my, immediate: true });
        }
        return memo;
      }
    },
    {
      from: () => [0, y.get()],
      bounds: { top: 0 },
      rubberband: true,
      threshold: 10,
    }
  );

  useEffect(() => {
    api.start({ y: window.innerHeight * initialPosition });
  }, [initialPosition]);

  useEffect(() => {
    if (isOpenPanel) {
      api.start({ y: 0 });
      setIsOpen(true);
    }
  }, [isOpenPanel]);

  useEffect(() => {
    if (SelectedStoreReducer?.filters.length > 0) {
      close();
    }
  }, [SelectedStoreReducer?.filters]);

  useEffect(() => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      // iOS specific adjustments
      api.start({ y: window.innerHeight * initialPosition });
      // setIsOpen(false); // Ensure it's closed by default
    }
  }, [api, initialPosition]);

  const moveUp = () => {
    updateIsatBottom(false);
    api.start({ y: window.innerHeight * initialPosition });
  };

  useEffect(() => {
    if (actvMode.drawLine?.mm?.route) {
      console.log("whic is", true);
      updateIsatBottom(true);
      api.start({ y: window.innerHeight * 0.9 });
    } else {
      updateIsatBottom(false);
      api.start({ y: window.innerHeight * initialPosition });
    }
  }, [actvMode.drawLine?.mm?.route, actvMode.actvModeOn]);

  return (
    <div className="bottom-panel">
      {canender && (
        <animated.div
          {...bind()}
          style={{ y, touchAction: "none" }}
          className={`panel   bg-white
        
        ${isOpen ? "" : "rounded-tr-3xl rounded-tl-3xl"}`}
        >
          <hr className="h-1 bg-[#262626] w-[90px] mt-1  sm-w-[20%] mx-auto rounded-xl " />
          <div className={`mt-2 ${headingStyle ? headingStyle : "w-[90%]"}`}>
            {isatBottom.current &&
            actvMode.drawLine?.mm?.route &&
            actvMode.actvModeOn
              ? optionalHeading
              : heading}
          </div>

          <div
            className={`${
              showClose
                ? "fixed -right-4 -top-2 "
                : isOpen
                ? " flex justify-end w-full"
                : "flex justify-end w-full"
            }`}
          >
            <img
              onClick={close}
              style={{
                backgroundColor: "transparent",
                // borderRadius: '20px',

                height: storeSowing
                  ? "60px"
                  : initialPosition === 0.2
                  ? "40px"
                  : "40px",
                cursor: "pointer",
                zIndex: 999999999999,
                marginRight: "10px",
              }}
              src={`${process.env.PUBLIC_URL}/images/cancelNav-1.svg`}
            />
            {isatBottom.current && (
              <div
                onClick={() => {
                  moveUp();
                }}
                style={{
                  // borderRadius: '20px',
                  width: "40px",
                  cursor: "pointer",
                  zIndex: 999999999999,
                  marginTop: "-120px",
                }}
              >
                <div
                  style={{ borderRadius: "50%" }}
                  className="p-2 bg-[#eeeeee]"
                >
                  <ArrowUpwardIcon></ArrowUpwardIcon>
                </div>
              </div>
            )}
          </div>
          <div className="panel-content ">{children}</div>
        </animated.div>
      )}
    </div>
  );
};

export default BottomPanelMap;
