import React, { useEffect, useState, useRef } from "react";
import { Marker, Source, Layer, Popup } from "react-map-gl";
import { DateTime } from "luxon";

import CustomOverlay from "../../map/customOverlay";
import { OrderStatusEnum } from "../constants/orderStatusEnum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPersonWalking,
} from "@fortawesome/free-solid-svg-icons";
import io from "socket.io-client";

import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setAvailableRunners,
  setselectedRunners,
  setselectedRunnersView,
} from "../../store/showRunners";
import { RootState } from "../../store";
import { Close } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
const mapLayer: any = {
  id: "route",
  type: "line",
  source: "route",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "orange",
    "line-width": 5,
  },
};
interface GetOrdersParams {
  date_from: string;
  date_to: string;
  accessToken: string;
}

const OrderIcons = (
  <FontAwesomeIcon icon={faLocationDot} bounce style={{ fontSize: 35 }} />
);

const RunnerIcons = (
  <FontAwesomeIcon icon={faPersonWalking} bounce style={{ fontSize: 25 }} />
);
const StartLocationIcon = (
  <FontAwesomeIcon
    icon={faLocationDot}
    style={{ fontSize: 50, color: "orange" }}
  />
);
const DeliveryIcon = (
  <FontAwesomeIcon
    icon={faLocationDot}
    beat
    style={{ color: "#85d016", fontSize: 50 }}
  />
);
function MapAdmin(props: any) {
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [Orders, setOrders] = useState([]);
  const [runnerOrders, setRunnerOrders] = useState<any>([]);
  const dispatchRedux = useDispatch();
  const [Runners, setRunners] = useState<any[]>([]);
  const [tokenInvalid, setTokenIvalid] = useState(false);
  const today = DateTime.local().toFormat("yyyy-MM-dd");
  const showRunners = useSelector((state: RootState) => state.showRunners);
  const showHelps = useSelector((state: RootState) => state.showHelps);

  const RunnersRef = useRef(Runners);
  const [selectedRUnners, setSelectedRUnners] = useState([]);

  useEffect(() => {
    setOrders([]);
    if (!navigator.geolocation) {
      alert("Turn on Your location");
      return;
    }

    navigator?.permissions?.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        getLocation();
      }
    });

    function getLocation() {
      console.log("ciao");
    }
  }, []);

  useEffect(() => {
    RunnersRef.current = Runners;
  }, [Runners]);

  useEffect(() => {
    setTokenIvalid(false);
    getRunners({
      accessToken: props.access_token,
    });
    getOrders({
      date_from: today,
      date_to: today,
      accessToken: props.access_token,
    });
  }, [props.refresh, showRunners.show_runners, props.access_token]);

  async function getRunners({ accessToken }: { accessToken: string }) {
    if (accessToken && !tokenInvalid) {
      const urlParams = new URLSearchParams({
        created_at: DateTime.local().toFormat("yyyy-MM-dd"),
      });
      const url = `${
        process.env.REACT_APP_API
      }/runner-position/?${urlParams.toString()}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        console.log("Network response was not ok " + response.statusText);
        if (response.status == 401) {
          setTokenIvalid(true);
        }
      } else {
        const responseData = await response.json();

        const uniqueData = removeDuplicatesById(responseData.data);

        setRunners(uniqueData);
      }
    }
  }

  const removeDuplicatesById = (array: any) => {
    const seenIds = new Set();
    return array.filter((item: any) => {
      if (seenIds.has(item.runner_id)) {
        return false;
      }
      seenIds.add(item.runner_id);
      return true;
    });
  };

  async function getOrders({
    date_from,
    date_to,
    accessToken,
  }: GetOrdersParams) {
    if (accessToken && !tokenInvalid) {
      const urlParams = new URLSearchParams({ date_from, date_to });
      const url = `${
        process.env.REACT_APP_API
      }/food/order/?${urlParams.toString()}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.access_token}`,
        },
      });

      if (!response.ok) {
        console.log("Network response was not ok " + response.statusText);
        if (response.status == 401) {
          setTokenIvalid(true);
        }
      } else {
        const responseData = await response.json();
        setRunnerOrders(responseData.data);
      }
    }
  }

  const isRunnerActive = (runner: any) => {
    const start = new Date();
    start.setMinutes(start.getMinutes() - 30);
    const end = new Date();
    end.setMinutes(end.getMinutes() + 30);

    const realTime = `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`;
    const startTime = `${start.getHours()}:${start.getMinutes()}:${start.getSeconds()}`;
    const endTime = `${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`;

    const active = runner?.users?.runner_days?.find((rd: any) => {
      const start_range =
        rd?.saturation_days?.range_hour?.start_range?.split(/T|\./)[1];
      const end_range =
        rd?.saturation_days?.range_hour?.end_range?.split(/T|\./)[1];

      return (
        (start_range <= startTime || start_range <= realTime) &&
        (end_range >= endTime || end_range >= realTime)
      );
    });
    return active || runner?.users?.runner_orders?.length;
  };

  useEffect(() => {
    if (props.showDirectioAdmin) {
      const order = props.showDirectioAdmin;
      let path_to_send = {
        lat: JSON.stringify(order.store.latitude),
        lng: JSON.stringify(order.store.longitude),
        lat1: JSON.stringify(order.food_orders)
          ? JSON.stringify(order.food_orders.latitude)
          : JSON.stringify(order.smart_logistics_orders)
          ? JSON.stringify(order.food_orders.smart_logistics_orders.latitude)
          : null,
        lng1: JSON.stringify(order.food_orders)
          ? JSON.stringify(order.food_orders.longitude)
          : JSON.stringify(order.smart_logistics_orders)
          ? JSON.stringify(order.food_orders.smart_logistics_orders.longitude)
          : null,
        steps: {
          duration: Number(JSON.stringify(order.runner_walking_time)),
          distance: JSON.stringify(order.distance),
        },
      };
      props.showpath(path_to_send);

      props.onReceiveData({
        steps: {
          duration: path_to_send.steps.duration,
          distance: path_to_send.steps.distance,
        },
      });
    }
  }, [props.showDirectioAdmin]);

  useEffect(() => {
    // Connect to Socket.io server
    const socket = io(`${process.env.REACT_APP_URL}`, {
      path: "/socket.io",
      transports: ["websocket", "polling"],
      secure: true,
    });
    if (props.access_token) {
      // Listen for runner position updates
      socket.on("runnerposition-update", async (updatedPosition: any[]) => {
        if (updatedPosition && updatedPosition[0]) {
          const data: any[] = [...RunnersRef.current];
          const foundIndex = data.findIndex(
            (r: any) => r.runner_id == updatedPosition[0].runner_id
          );
          if (foundIndex != -1) {
            data[foundIndex] = updatedPosition[0];
          } else {
            data.push(updatedPosition[0]);
          }
          setRunners([...data]);
        } else {
          await getRunners({
            accessToken: props.access_token,
          });
        }
        console.log("Runner position updated:", updatedPosition);
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [props.access_token]);

  useEffect(() => {
    const runners_working = Runners.filter(isRunnerActive);

    dispatchRedux(setAvailableRunners(Runners));
  }, [Runners]);

  const handleSelectedRunner = (run: any) => {
    const runnnerObj = {
      type: "runner",
      data: run,
      runner_order: runnerOrders,
      accessToken: props.access_token,
    };

    dispatchRedux(setselectedRunnersView(runnnerObj));
  };

  return (
    <div>
      <div className="fixed  top-[20px] left-[300px] max-w-[100%] overflow-x-auto">
        {showRunners.show_runners && (
          <div className="flex flex items-center justify-center gap-[20px]">
            {Runners.filter(isRunnerActive).map(
              (runner: any, index: number) => {
                const activeOrders = runnerOrders.filter((order: any) => {
                  return (
                    order.status.name !== OrderStatusEnum.CONSEGNATO &&
                    order.status.name !==
                      OrderStatusEnum.RIFIUTATO_DAL_RISTORANTE &&
                    order.status.name !== OrderStatusEnum.RIFIUTATO_DA_COCAI
                  );
                });

                const runnerOrdersFiltered = activeOrders.filter(
                  (order: any) => {
                    return (
                      order?.runner_orders[0]?.user_id === runner?.runner_id
                    );
                  }
                );

                const urgentStatuses = [
                  OrderStatusEnum.RITIRATO,
                  OrderStatusEnum.PRONTO,
                  OrderStatusEnum.IN_ATTESA_DEL_RISTORANTE,
                ];

                const urgentOrders = runnerOrdersFiltered.filter((orddd: any) =>
                  urgentStatuses.includes(orddd.status.name)
                );

                // 1. If there are urgent orders, select the one with the closest pickup time.
                // 2. Otherwise, if there are any orders, select the one with the closest pickup time.
                let orderForBorder;
                if (urgentOrders.length > 0) {
                  orderForBorder = urgentOrders.sort(
                    (a: any, b: any) =>
                      new Date(a.pickupTime).getTime() -
                      new Date(b.pickupTime).getTime()
                  )[0];
                } else if (runnerOrdersFiltered.length > 0) {
                  orderForBorder = runnerOrdersFiltered.sort(
                    (a: any, b: any) =>
                      new Date(a.pickupTime).getTime() -
                      new Date(b.pickupTime).getTime()
                  )[0];
                }

                const borderStyle = orderForBorder
                  ? `${orderForBorder.status.styles.background} 5px solid`
                  : "";

                return (
                  <div
                    style={{ border: borderStyle }}
                    onClick={() => handleSelectedRunner(runner)}
                    className={`
        ${
          showRunners.selected_runners_view.some(
            (r: any) => r.data.runner_id === runner.runner_id
          )
            ? "bg-[#fab626]"
            : "bg-[grey]"
        }
        rounded-xl pl-2 pr-2 cursor-pointer
      `}
                    key={index}
                  >
                    {runner.users.fullname}
                  </div>
                );
              }
            )}
          </div>
        )}
      </div>
      {showHelps.show_helps && (
        <div
          // style={{ zIndex: }}
          className="fixed  top-[20px] right-[20px] h-[200px] w-[200px] bg-[white] rounded"
        >
          <div className=" max-w-[100%] overflow-auto max-h-[200px]">
            {props.allOrders && (
              <div className="flex flex-col items-center justify-center gap-[5px] overflow-auto">
                {props.allOrders.map((ords: any, index: number) => {
                  const helpReq = ords.help_requests;

                  helpReq.sort((a: any, b: any) => {
                    if (a.served === b.served) {
                      return 0;
                    }

                    return a.served ? 1 : -1;
                  });

                  return helpReq.map((help: any) => {
                    return (
                      <div
                        className={` rounded-xl pl-2 pr-2 flex flex-col gap-[10px] mt-2 cursor-pointer`}
                        key={index}
                      >
                        <div
                          className={`flex flex-col  ${
                            !help.served ? "bg-[wheat]" : "bg-[green]"
                          } p-1  rounded w-[100%]`}
                        >
                          <span>
                            Motivation: {help.motivation.replaceAll("_", " ")}
                          </span>
                          <span>Method: {help.method}</span>
                          <span>Note: {help.note}</span>
                          <span>Phone number: {help.phone_number}</span>
                          <span>
                            Served: {help.served ? "Served" : "Not Served"}
                          </span>
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
            )}
          </div>
        </div>
      )}
      {/* {Orders.map((order: any, index: number) => (
        <Marker
          key={`marker-${index}`}
          longitude={order.store.longitude}
          latitude={order.store.latitude}
          anchor="bottom"
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup

            e.originalEvent.stopPropagation();
          }}
        >
          {OrderIcons}
        </Marker>
      ))} */}

      {showRunners.show_runners &&
        Runners.filter(isRunnerActive).map((runner: any, index: number) => {
          // const [runnerId, runs]: any = Object.entries(runner)[0];
          // console.log("runs", runs);
          const activeRuns = runner;
          // ?.filter(isRunnerActive);

          const breakCrumbs = JSON.parse(activeRuns.positions).positions.sort(
            (a: any, b: any) => {
              return (
                new Date(b?.timestamp).getTime() -
                new Date(a?.timestamp).getTime()
              );
            }
          );

          const isSelected = showRunners.selected_runners_view.some(
            (r: any) => r.data?.runner_id === activeRuns?.runner_id
          );

          return breakCrumbs.slice(0, 7).map((run: any, index: any) => {
            return (
              <Marker
                key={`marker-${index}`}
                longitude={index === 0 ? activeRuns.longitude : run.longitude}
                latitude={index === 0 ? activeRuns.latitude : run.latitude}
                anchor="bottom"
                onClick={(e) => {
                  // If we let the click event propagates to the map, it will immediately close the popup

                  e.originalEvent.stopPropagation();
                  dispatchRedux(
                    setselectedRunners({
                      type: "runner",
                      data: activeRuns,
                      runner_order: runnerOrders,
                      accessToken: props.access_token,
                    })
                  );
                  props.onrecieveRunnerDetail({
                    type: "runner",
                    data: run,
                    runner_order: runnerOrders,
                    accessToken: props.access_token,
                  });
                  // setPopupInfo();
                }}
              >
                {index === 0 ? (
                  <FontAwesomeIcon
                    icon={faPersonWalking}
                    bounce
                    style={{
                      fontSize: 25,
                      color: isSelected ? "#fab626" : "black",
                    }}
                  />
                ) : (
                  <CircleIcon
                    style={{ color: "green", fontSize: "5px" }}
                  ></CircleIcon>
                )}
              </Marker>
            );
          });
        })}

      {props.steps &&
        props.steps?.legs[0]?.steps &&
        props.steps?.legs[0]?.steps?.map((step: any, index: any) => (
          <Marker
            key={`step-${index}`}
            longitude={step.maneuver.location[0]}
            latitude={step.maneuver.location[1]}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();
            }}
          >
            {index === 0 ? (
              StartLocationIcon
            ) : index === props.steps.legs[0].steps.length - 1 ? (
              DeliveryIcon
            ) : (
              <i
                className="fa fa-map-marker"
                title="Step"
                style={{ color: "red", fontSize: "1em", display: "none" }}
              ></i>
            )}
          </Marker>
        ))}
      {props.route && (
        <Source type="geojson" data={props.route}>
          <Layer {...mapLayer} />
        </Source>
      )}
      {props.popupInfo && (
        <Popup
          anchor="top"
          longitude={Number(props.popupInfo.marker.longitude)}
          latitude={Number(props.popupInfo.marker.latitude)}
          onClose={() => {
            props.removePopUp();
            setPopupInfo(null);
          }}
          className="custom-popup"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <span className="popup_address_text">
                {props.popupInfo.marker.business_name}
              </span>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default MapAdmin;
