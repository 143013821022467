import { OrderStatusEnum } from "../../lib/constants/orderStatusEnum";

// Action Types
const TOGGLE_STORES = "TOGGLE_STORES";
const STORE_TO_SHOW = "STORE_TO_SHOW";
const SET_STORE_ORDERS = "SET_STORE_ORDERS";
const REMOVE_ALL = "REMOVE_ALL";
// Initial State
const initialState: any = {
  show_stores: false,
  store_to_show: null,
  storeOrders: [],
};

// Reducer
export function showStores(
  state = initialState,
  action: { type: any; payload: any }
) {
  switch (action.type) {
    case TOGGLE_STORES:
      return {
        ...state,
        show_stores: !state.show_stores,
      };
    case STORE_TO_SHOW:
      return {
        ...state,
        store_to_show: action.payload.store,
      };
    case SET_STORE_ORDERS:
      const filteredOrders = action.payload.orders.filter((ords: any) => {
        return (
          ords.status.name !== OrderStatusEnum.CONSEGNATO &&
          ords.status.name !== OrderStatusEnum.RIFIUTATO_DA_COCAI &&
          ords.status.name !== OrderStatusEnum.RIFIUTATO_DAL_RISTORANTE
        );
      });
      return {
        ...state,
        storeOrders: filteredOrders,
      };
    case REMOVE_ALL:
      return {
        show_stores: false,
      };
    default:
      return state;
  }
}

// Action Creators
export function changeeShowStore(mode: boolean) {
  return { type: TOGGLE_STORES, payload: { mode } };
}

export function storeToShow(store: any) {
  return { type: STORE_TO_SHOW, payload: { store } };
}
export function setStoreOrders(orders: any) {
  return { type: SET_STORE_ORDERS, payload: { orders } };
}

export function removestores() {
  return { type: REMOVE_ALL };
}
