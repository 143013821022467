export const MapEventType = "cocai-map-message";

export interface IMapProps {
  onReceiveData: any;
  removePath: boolean;
  onrecieveRunnerDetail: any;
  showDirectioAdmin: any;
  onrecieonReceiveToken: any;
}

export interface Coords {
  lat: number;
  lng: number;
}

export enum EMapType {
  toLocation = "map-to-location",
  filters = "map-filter",
  setPoints = "set-points",
  removePoints = "remove-points",
  resetPoints = "reset-points",
  setPath = "set-path",
  setStepByStep = "set-step-by-step",
  onClick = "on-click",
  clearMap = "clear-map",
  removePath = "remove_path",
  refresh = "refresh",
  for_runner = "for_runner",
  for_admin = "for_admin",
  point_for_stores = "point_for_stores",
  actvMode = "actvMode",
}

export interface IAppPayload {
  type: EMapType;
  location?: Coords;
  points?: { point: Coords; marker: string }[];
  search?: string;
  subcategories?: number[];
  functions?: ((data: any, extra?: any) => Promise<boolean>)[];
  results?: any;
  stepByStep?: boolean;
  actv_markers?: any;
  userLocation?: any;
}

export enum UserType {
  runner = "runner",
  admin = "admin",
  store = "store",
}
